﻿export enum PartnerTypeEnum {
    Dealer = 1,
    Financer = 2
}

export enum EmployeeTypeEnum {
    Employed = 1,
    SelfEmployed
}

export enum GenderEnum {
    Female = 1,
    Male
}

export enum CivilStatusEnum {
    Single = 1,
    Married = 2,
    LegallySeparated,
    Widowed
}

export enum ResidenceStatusEnum {
    Owned = 1,
    Mortgage,
    Rent,
    LivingWithParents
}

export enum EmploymentStatusEnum {
    Probationary = 1,
    Casual,
    ProjectBased,
    Regular
}

export enum CitizenshipEnum {
    Filipino = 1,
    Others
}