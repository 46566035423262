﻿export class ImportConfigModel {
    public Title: string;
    public Step: number;
    public TemplateFileUrl: string;
    public ImportFileSelected: File;
    constructor(title: string, tempUrl: string) {
        this.TemplateFileUrl = tempUrl;
        this.Title = title;
        this.Step = 1;
    }
}

export class ImportResult {
    public CountSuccess: number;
    public CountErrror: number;
    public ErrorFileUrl: string;
    public Message: string;
}