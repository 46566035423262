export class StringHelper {
    public static buildQueryStrings(source: Object) {
        let target = '';
        let isFirst = true;
        Object.keys(source).forEach((key: string) => {
            const value: string | number | boolean | Date = source[key];
            if ((typeof value !== 'undefined') && (value !== null)) {
                target += (isFirst ? '?' : '&') + key + '=' + value.toString();
            }
            isFirst = false;
        });
        return target;
    }
}