﻿import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as Excel from "../../../node_modules/exceljs/dist/exceljs.min.js";
import * as ExcelProper from "exceljs";

import { DatePipe } from '../../../node_modules/@angular/common';
import { DealerSummary } from './model/dashboard-model';
import { LeadsModel } from './model/leads-model';
import { ExportType } from '../utils/enums/leads-type.enum.js';
import { PartnerTypeEnum } from '../utils/enums/dealeraccount.enum.js';

@Injectable({
    providedIn: 'root'
})

export class ExcelService {
    constructor(private datePipe: DatePipe) { }

    public generateDashboard(dataSummary: DealerSummary[]): void {

        const title = 'Dealership Lead dashboard';
        const header = ["No", "Lead source", "Total leads", "Pending leads", "Onboarded leads", "Pending onboarded leads", "Rejected leads"]

        //Create workbook and worksheet
        let workbook: ExcelProper.Workbook = new Excel.Workbook();
        let worksheet = workbook.addWorksheet('Summary leads info');

        // worksheet.addRow([]);

        // //Add Row and formatting
        // let titleRow = worksheet.addRow([title]);
        // titleRow.font = { name: 'Comic Sans MS', family: 4, size: 16, underline: 'double', bold: true }
        // worksheet.addRow(['Created date : ' + this.datePipe.transform(new Date(), 'medium')])

        // worksheet.addRow([]);

        // //Add Header Row
        // let headerRow = worksheet.addRow(header);
        // worksheet.getRow(5).height = 28;
        // worksheet.getColumn(2).width = 25;
        // worksheet.getColumn(3).width = 25;
        // worksheet.getColumn(4).width = 25;
        // worksheet.getColumn(5).width = 30;
        // worksheet.getColumn(6).width = 30;
        // worksheet.getColumn(7).width = 30;

        // // Cell Style : Fill and Border
        // let countCell = 0;
        // headerRow.eachCell((cell, number) => {
        //     countCell++;
        //     cell.fill = {
        //         type: 'pattern',
        //         pattern: 'solid',
        //         fgColor: { argb: '70A8ED' },
        //         bgColor: { argb: '33333333' }
        //     }
        //     cell.font = { name: 'Comic Sans MS', family: 4, size: 11, bold: true };

        //     if (countCell == 1) {
        //         cell.alignment = { vertical: 'middle', horizontal: 'center' };
        //     } else {
        //         cell.alignment = { vertical: 'middle', horizontal: 'left' };
        //     }
        // });

        // let countRowData = 5;
        // let countRowStt = 0;
        // dataSummary.forEach(d => {

        //     countRowData++;
        //     countRowStt++;
        //     worksheet.addRow([]);
        //     worksheet.getRow(countRowData).getCell(1).value = countRowStt.toString();
        //     worksheet.getRow(countRowData).getCell(2).value = d.LeadSourceName;
        //     worksheet.getRow(countRowData).getCell(3).value = d.TotalLeads;
        //     worksheet.getRow(countRowData).getCell(4).value = d.PendingLeads;
        //     worksheet.getRow(countRowData).getCell(5).value = d.OnBoardedLeads;
        //     worksheet.getRow(countRowData).getCell(6).value = d.PendingOnBoardedLeads;
        //     worksheet.getRow(countRowData).getCell(7).value = d.RejectedLeads;
        //     worksheet.getRow(countRowData).height = 25;

        //     //style cell
        //     worksheet.getRow(countRowData).getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
        //     worksheet.getRow(countRowData).getCell(2).alignment = { vertical: 'middle', horizontal: 'left' };
        //     worksheet.getRow(countRowData).getCell(3).alignment = { vertical: 'middle', horizontal: 'left' };
        //     worksheet.getRow(countRowData).getCell(4).alignment = { vertical: 'middle', horizontal: 'left' };
        //     worksheet.getRow(countRowData).getCell(5).alignment = { vertical: 'middle', horizontal: 'left' };
        //     worksheet.getRow(countRowData).getCell(6).alignment = { vertical: 'middle', horizontal: 'left' };
        //     worksheet.getRow(countRowData).getCell(7).alignment = { vertical: 'middle', horizontal: 'left' };
        // });

        // //Generate Excel File with given name
        // workbook.xlsx.writeBuffer().then(function (buffer: any) {
        //     let blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        //     //var blob = new Blob(["hello world"], { type: "text/plain;charset=utf-8" });
        //     saveAs(blob, 'dashboad-output.xlsx');
        // });
    }

    public exportExcel(dataSummary: LeadsModel[], title: string): void {
        //const title = type == ExportType.PendingLeads ? 'Pending leads information' : type == ExportType.PendingOnboardingLeads ? 'Pending Onboarding leads information' : 'Onboarding leads information';
        const header = ["No", "First Name", "Middle Name", "Last Name", "Phone", "Email", "Address", "Created at", "Note", "Action Type", "Brand", "Model", "Year", "Variant", "Transmission", "Price", "Car Use", "Car Loan", "Already own the car", "Plan to buy a car"]
        //Create workbook and worksheet
        let workbook: ExcelProper.Workbook = new Excel.Workbook();
        //let workbook = new Excel.stream.xlsx.WorkbookWriter({ useSharedStrings: true, useStyles: true });
        let worksheet = workbook.addWorksheet(title);

        worksheet.addRow([]);

        //Add Row and formatting
        let titleRow = worksheet.addRow([title + "information"]);
        titleRow.font = { name: 'Comic Sans MS', family: 4, size: 16, underline: 'double', bold: true }
        worksheet.addRow(['Created date : ' + this.datePipe.transform(new Date(), 'medium')])
        worksheet.addRow([]);

        //Add Header Row
        let headerRow = worksheet.addRow(header);
        worksheet.getRow(5).height = 28;
        let columnIndex = 1;

        const columnSTT = columnIndex++;
        const columnFirstName = columnIndex++;
        const columnMiddleName = columnIndex++;
        const columnLastName = columnIndex++;
        const columnPhone = columnIndex++;
        const columnEmail = columnIndex++;
        const columnAddress = columnIndex++;
        const columnCreatedAt = columnIndex++;
        const columnNote = columnIndex++;
        const colActionType = columnIndex++;
        const columnBrand = columnIndex++;
        const columnModel = columnIndex++;
        const columnYear = columnIndex++;
        const columnVariant = columnIndex++;
        const colTransmission = columnIndex++;
        const colPrice = columnIndex++;
        const colCarUse = columnIndex++;
        const colCarLoan = columnIndex++;
        const colOwnCar = columnIndex++;
        const colPlanToBuyCar = columnIndex++;

        worksheet.getColumn(columnSTT).width = 10;
        worksheet.getColumn(columnFirstName).width = 20;
        worksheet.getColumn(columnMiddleName).width = 20;
        worksheet.getColumn(columnLastName).width = 20;
        worksheet.getColumn(columnPhone).width = 20;
        worksheet.getColumn(columnEmail).width = 30;
        worksheet.getColumn(columnAddress).width = 30;
        worksheet.getColumn(columnBrand).width = 30;
        worksheet.getColumn(columnModel).width = 30;
        worksheet.getColumn(columnYear).width = 30;
        worksheet.getColumn(columnVariant).width = 30;
        worksheet.getColumn(columnCreatedAt).width = 30;
        worksheet.getColumn(columnNote).width = 30;
        worksheet.getColumn(colActionType).width = 30;
        worksheet.getColumn(colTransmission).width = 20;
        worksheet.getColumn(colPrice).width = 20;
        worksheet.getColumn(colCarUse).width = 20;
        worksheet.getColumn(colCarLoan).width = 20;
        worksheet.getColumn(colOwnCar).width = 20;
        worksheet.getColumn(colPlanToBuyCar).width = 30;

        // Cell Style : Fill and Border
        let countCell = 0;
        headerRow.eachCell((cell, number) => {
            countCell++;
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '70A8ED' },
                bgColor: { argb: '33333333' }
            }
            cell.font = { name: 'Comic Sans MS', family: 4, size: 11, bold: true };

            if (countCell == 1) {
                cell.alignment = { vertical: 'middle', horizontal: 'center' };
            } else {
                cell.alignment = { vertical: 'middle', horizontal: 'left' };
            }
        });

        let rowIndex = 5;
        let stt = 0;
        dataSummary.forEach(d => {
            stt++;
            rowIndex++;

            let ListName = d.LeadsName.trim().split(' ');
            ListName = ListName.filter(s => s.toString() !== '');
            let FirstName = '';
            let MiddleName = '';
            let LastName = '';
            if (ListName.length == 1) {
                FirstName = ListName[0];
            } else if (ListName.length == 2) {
                FirstName = ListName[0];
                LastName = ListName[1];
            } else if (ListName.length > 2) {
                FirstName = ListName[0];
                LastName = ListName[ListName.length - 1];
                for (let index = 1; index < ListName.length - 1; index++) {
                    MiddleName += ListName[index] + ' ';
                }

                MiddleName = MiddleName.trim();
            }

            worksheet.getRow(rowIndex).getCell(columnSTT).value = stt.toString();
            worksheet.getRow(rowIndex).getCell(columnSTT).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            worksheet.getRow(rowIndex).getCell(columnFirstName).value = FirstName;
            worksheet.getRow(rowIndex).getCell(columnMiddleName).value = MiddleName;
            worksheet.getRow(rowIndex).getCell(columnLastName).value = LastName;
            worksheet.getRow(rowIndex).getCell(columnPhone).value = this.getStringObject(d.LeadsPhone);
            worksheet.getRow(rowIndex).getCell(columnEmail).value = this.getStringObject(d.LeadsEmail);
            worksheet.getRow(rowIndex).getCell(columnAddress).value = this.getStringObject(d.LeadsAddress);
            worksheet.getRow(rowIndex).getCell(columnCreatedAt).value = d.LisLeadDetail.length > 0 ? d.LisLeadDetail[0].CreatedDateStr : '';
            worksheet.getRow(rowIndex).getCell(columnNote).value = this.getStringObject(d.DealerNote);

            //if (type != ExportType.OnboardingLeads) {
            for (let index = 0; index < d.LisLeadDetail.length; index++) {
                const detail = d.LisLeadDetail[index];
                worksheet.getRow(rowIndex).getCell(colActionType).value = detail.ActionTypeStr;
                worksheet.getRow(rowIndex).getCell(columnBrand).value = this.getStringObject(detail.ProducedBrandName);
                worksheet.getRow(rowIndex).getCell(columnModel).value = this.getStringObject(detail.ProducedModelName);
                worksheet.getRow(rowIndex).getCell(columnYear).value = this.getStringObject(detail.ProductYear);
                worksheet.getRow(rowIndex).getCell(columnVariant).value = this.getStringObject(detail.Variant);

                worksheet.getRow(rowIndex).getCell(colTransmission).value = detail.TransmissionStr;
                worksheet.getRow(rowIndex).getCell(colPrice).value = this.formatNumber(detail.Price);
                worksheet.getRow(rowIndex).getCell(colCarUse).value = detail.CarUseStr;
                worksheet.getRow(rowIndex).getCell(colCarLoan).value = detail.CarLoanStr;
                worksheet.getRow(rowIndex).getCell(colOwnCar).value = detail.OwnCar;
                worksheet.getRow(rowIndex).getCell(colPlanToBuyCar).value = detail.PlanToBuyCarStr;

                if (index < d.LisLeadDetail.length - 1) {
                    rowIndex++;
                }
            }
        });

        //Generate Excel File with given name
        workbook.xlsx.writeBuffer().then(function (buffer: any) {
            const blob = new Blob([buffer], { type: 'application/vnd.ms-excel' });
            FileSaver.saveAs(blob, title + '-output.xlsx');
        });

    }
    public exportLeadWithDetailCustomer(dataSource: LeadsModel[], title: string) {
        const headerColumnLeadData = ["No", "First Name", "Middle Name", "Last Name", "Phone", "Email", "Address"];
        const headerColumnActionDetail = ["Created at", "Note", "Action Type", "Brand", "Model", "Year", "Variant", "Transmission", "Price", "Car Use", "Car Loan", "Already own the car", "Plan to buy a car"];
        const headerColumnCustomerdata = ["Civil status", "Gender", "Citizenship", "Date of birth", "Place of birth", "Region", "city", "Residence/Permanent address", "Present address", "Years in address", "Residence status", "Monthly salary", "Prefered lender", "Employment", "Employer name",
            "Status of employment", "Address of employer", "Office number", "Number of years with employer ", "Business name", "Business address", "Contact number", "Position", "Nature of business", "Years in business"];
        const header = [...headerColumnLeadData, ...headerColumnCustomerdata, ...headerColumnActionDetail];
        //Create workbook and worksheet
        let workbook: ExcelProper.Workbook = new Excel.Workbook();
        let worksheet = workbook.addWorksheet(title);

        worksheet.addRow([]);

        //Add Row and formatting
        let titleRow = worksheet.addRow([title + "information"]);
        titleRow.font = { name: 'Comic Sans MS', family: 4, size: 16, underline: 'double', bold: true }
        worksheet.addRow(['Created date : ' + this.datePipe.transform(new Date(), 'medium')])
        worksheet.addRow([]);

        //Add Header Row
        let headerRow = worksheet.addRow(header);
        worksheet.getRow(5).height = 28;

        let columnIndex = 1;
        const columnSTT = columnIndex++;
        const columnFirstName = columnIndex++;
        const columnMiddleName = columnIndex++;
        const columnLastName = columnIndex++;
        const columnPhone = columnIndex++;
        const columnEmail = columnIndex++;
        const columnAddress = columnIndex++;
        //customer detail infor
        const columnCivilStatus = columnIndex++;
        const columnGender = columnIndex++;
        const columnCitizenship = columnIndex++;
        const columnDateBirth = columnIndex++;
        const columnPlaceBirth = columnIndex++;
        const columnRegion = columnIndex++;
        const columnCity = columnIndex++;
        const columnPermanentAddress = columnIndex++;
        const columnPresentAddress = columnIndex++;
        const columnYearsInAddress = columnIndex++;
        const columnResidenceStatus = columnIndex++;
        const columnMonthlySalary = columnIndex++;
        const columnPreferedLender = columnIndex++;
        const columnEmployment = columnIndex++;
        const columnEmployerName = columnIndex++;
        const columnStatusEmployment = columnIndex++;
        const columnAddressEmployer = columnIndex++;
        const columnOfficeNumber = columnIndex++;
        const columnNumberOfYearsWithEmployer = columnIndex++;
        const columnBusinessName = columnIndex++;
        const columnBusinessAddress = columnIndex++;
        const columnContactNumber = columnIndex++;
        const columnPosition = columnIndex++;
        const columnNatureOfBusiness = columnIndex++;
        const columnYearsinBusiness = columnIndex++;
        //lead action detail
        const columnCreatedAt = columnIndex++;
        const columnNote = columnIndex++;
        const colActionType = columnIndex++;
        const columnBrand = columnIndex++;
        const columnModel = columnIndex++;
        const columnYear = columnIndex++;
        const columnVariant = columnIndex++;
        const colTransmission = columnIndex++;
        const colPrice = columnIndex++;
        const colCarUse = columnIndex++;
        const colCarLoan = columnIndex++;
        const colOwnCar = columnIndex++;
        const colPlanToBuyCar = columnIndex++;

        worksheet.getColumn(columnSTT).width = 10;
        worksheet.getColumn(columnFirstName).width = 20;
        worksheet.getColumn(columnMiddleName).width = 20;
        worksheet.getColumn(columnLastName).width = 20;
        worksheet.getColumn(columnPhone).width = 20;
        worksheet.getColumn(columnEmail).width = 30;
        worksheet.getColumn(columnAddress).width = 30;

        worksheet.getColumn(columnCivilStatus).width = 20;
        worksheet.getColumn(columnGender).width = 20;
        worksheet.getColumn(columnCitizenship).width = 20;
        worksheet.getColumn(columnDateBirth).width = 20;
        worksheet.getColumn(columnPlaceBirth).width = 30;
        worksheet.getColumn(columnRegion).width = 20;
        worksheet.getColumn(columnCity).width = 20;
        worksheet.getColumn(columnPermanentAddress).width = 30;
        worksheet.getColumn(columnPresentAddress).width = 30;
        worksheet.getColumn(columnYearsInAddress).width = 20;
        worksheet.getColumn(columnResidenceStatus).width = 20;
        worksheet.getColumn(columnMonthlySalary).width = 20;
        worksheet.getColumn(columnPreferedLender).width = 20;
        worksheet.getColumn(columnEmployment).width = 20;
        worksheet.getColumn(columnEmployerName).width = 20;
        worksheet.getColumn(columnStatusEmployment).width = 20;
        worksheet.getColumn(columnAddressEmployer).width = 30;
        worksheet.getColumn(columnOfficeNumber).width = 20;
        worksheet.getColumn(columnNumberOfYearsWithEmployer).width = 10;
        worksheet.getColumn(columnBusinessName).width = 30;
        worksheet.getColumn(columnBusinessAddress).width = 30;
        worksheet.getColumn(columnContactNumber).width = 20;
        worksheet.getColumn(columnPosition).width = 10;
        worksheet.getColumn(columnNatureOfBusiness).width = 30;
        worksheet.getColumn(columnYearsinBusiness).width = 10;

        worksheet.getColumn(columnBrand).width = 30;
        worksheet.getColumn(columnModel).width = 30;
        worksheet.getColumn(columnYear).width = 30;
        worksheet.getColumn(columnVariant).width = 30;
        worksheet.getColumn(columnCreatedAt).width = 30;
        worksheet.getColumn(columnNote).width = 30;
        worksheet.getColumn(colActionType).width = 30;
        worksheet.getColumn(colTransmission).width = 20;
        worksheet.getColumn(colPrice).width = 20;
        worksheet.getColumn(colCarUse).width = 20;
        worksheet.getColumn(colCarLoan).width = 20;
        worksheet.getColumn(colOwnCar).width = 20;
        worksheet.getColumn(colPlanToBuyCar).width = 30;

        // Cell Style : Fill and Border
        let countCell = 0;
        headerRow.eachCell((cell, number) => {
            countCell++;
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '70A8ED' },
                bgColor: { argb: '33333333' }
            }
            cell.font = { name: 'Comic Sans MS', family: 4, size: 11, bold: true };

            if (countCell == 1) {
                cell.alignment = { vertical: 'middle', horizontal: 'center' };
            } else {
                cell.alignment = { vertical: 'middle', horizontal: 'left' };
            }
        });

        let rowIndex = 5;
        let stt = 0;
        dataSource.forEach(d => {
            stt++;
            rowIndex++;

            let ListName = d.LeadsName.trim().split(' ');
            ListName = ListName.filter(s => s.toString() !== '');
            let FirstName = '';
            let MiddleName = '';
            let LastName = '';
            if (ListName.length == 1) {
                FirstName = ListName[0];
            } else if (ListName.length == 2) {
                FirstName = ListName[0];
                LastName = ListName[1];
            } else if (ListName.length > 2) {
                FirstName = ListName[0];
                LastName = ListName[ListName.length - 1];
                for (let index = 1; index < ListName.length - 1; index++) {
                    MiddleName += ListName[index] + ' ';
                }

                MiddleName = MiddleName.trim();
            }

            worksheet.getRow(rowIndex).getCell(columnSTT).value = stt.toString();
            worksheet.getRow(rowIndex).getCell(columnSTT).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            worksheet.getRow(rowIndex).getCell(columnFirstName).value = FirstName;
            worksheet.getRow(rowIndex).getCell(columnMiddleName).value = MiddleName;
            worksheet.getRow(rowIndex).getCell(columnLastName).value = LastName;
            worksheet.getRow(rowIndex).getCell(columnPhone).value = this.getStringObject(d.LeadsPhone);
            worksheet.getRow(rowIndex).getCell(columnEmail).value = this.getStringObject(d.LeadsEmail);
            worksheet.getRow(rowIndex).getCell(columnAddress).value = this.getStringObject(d.LeadsAddress);
            if (d.Customer !== null || d.Customer != undefined) {
                worksheet.getRow(rowIndex).getCell(columnCivilStatus).value = d.Customer.CivilStatusName;
                worksheet.getRow(rowIndex).getCell(columnGender).value = d.Customer.GenderName;
                worksheet.getRow(rowIndex).getCell(columnCitizenship).value = d.Customer.CitizenshipName;
                worksheet.getRow(rowIndex).getCell(columnDateBirth).value = d.Customer.DateOfBirthStr;
                worksheet.getRow(rowIndex).getCell(columnPlaceBirth).value = d.Customer.PlaceOfBirth;
                worksheet.getRow(rowIndex).getCell(columnRegion).value = d.Customer.RegionName;
                worksheet.getRow(rowIndex).getCell(columnCity).value = d.Customer.CityName;
                worksheet.getRow(rowIndex).getCell(columnPermanentAddress).value = d.Customer.Residence;
                worksheet.getRow(rowIndex).getCell(columnPresentAddress).value = d.Customer.Address;
                worksheet.getRow(rowIndex).getCell(columnYearsInAddress).value = d.Customer.YearsinAddress;
                worksheet.getRow(rowIndex).getCell(columnResidenceStatus).value = d.Customer.ResidenceStatusName;
                worksheet.getRow(rowIndex).getCell(columnMonthlySalary).value = d.Customer.MonthlySalary;
                worksheet.getRow(rowIndex).getCell(columnPreferedLender).value = d.Customer.PreferedLender;
                worksheet.getRow(rowIndex).getCell(columnEmployment).value = d.Customer.EmploymentStr;
                worksheet.getRow(rowIndex).getCell(columnEmployerName).value = d.Customer.EmployerName;
                worksheet.getRow(rowIndex).getCell(columnStatusEmployment).value = d.Customer.StatusOfEmploymentStr;
                worksheet.getRow(rowIndex).getCell(columnAddressEmployer).value = d.Customer.AddressOfEmployer;
                worksheet.getRow(rowIndex).getCell(columnOfficeNumber).value = d.Customer.OfficeNumber;
                worksheet.getRow(rowIndex).getCell(columnNumberOfYearsWithEmployer).value = d.Customer.NumberOfYearsWithEmployer;
                worksheet.getRow(rowIndex).getCell(columnBusinessName).value = d.Customer.BusinessName;
                worksheet.getRow(rowIndex).getCell(columnBusinessAddress).value = d.Customer.BusinessAddress;
                worksheet.getRow(rowIndex).getCell(columnContactNumber).value = d.Customer.ContactNumber;
                worksheet.getRow(rowIndex).getCell(columnPosition).value = d.Customer.Position;
                worksheet.getRow(rowIndex).getCell(columnNatureOfBusiness).value = d.Customer.NatureOfBusiness;
                worksheet.getRow(rowIndex).getCell(columnYearsinBusiness).value = d.Customer.YearsinBusiness;
            }
            worksheet.getRow(rowIndex).getCell(columnCreatedAt).value = d.LisLeadDetail.length > 0 ? d.LisLeadDetail[0].CreatedDateStr : '';
            worksheet.getRow(rowIndex).getCell(columnNote).value = this.getStringObject(d.DealerNote);

            //if (type != ExportType.OnboardingLeads) {
            for (let index = 0; index < d.LisLeadDetail.length; index++) {
                const detail = d.LisLeadDetail[index];
                worksheet.getRow(rowIndex).getCell(colActionType).value = detail.ActionTypeStr;
                worksheet.getRow(rowIndex).getCell(columnBrand).value = this.getStringObject(detail.ProducedBrandName);
                worksheet.getRow(rowIndex).getCell(columnModel).value = this.getStringObject(detail.ProducedModelName);
                worksheet.getRow(rowIndex).getCell(columnYear).value = this.getStringObject(detail.ProductYear);
                worksheet.getRow(rowIndex).getCell(columnVariant).value = this.getStringObject(detail.Variant);

                worksheet.getRow(rowIndex).getCell(colTransmission).value = detail.TransmissionStr;
                worksheet.getRow(rowIndex).getCell(colPrice).value = this.formatNumber(detail.Price);
                worksheet.getRow(rowIndex).getCell(colCarUse).value = detail.CarUseStr;
                worksheet.getRow(rowIndex).getCell(colCarLoan).value = detail.CarLoanStr;
                worksheet.getRow(rowIndex).getCell(colOwnCar).value = detail.OwnCar;
                worksheet.getRow(rowIndex).getCell(colPlanToBuyCar).value = detail.PlanToBuyCarStr;

                if (index < d.LisLeadDetail.length - 1) {
                    rowIndex++;
                }
            }
        });

        //Generate Excel File with given name
        workbook.xlsx.writeBuffer().then(function (buffer: any) {
            //let blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const blob = new Blob([buffer], { type: 'application/vnd.ms-excel' });
            FileSaver.saveAs(blob, title + '-output.xlsx');
        });
    }
    public getStringObject(obj: any) {
        if (obj === null || obj === undefined || obj === '') {
            return '';
        }
        return obj.toString()
    }

    formatNumber(obj: number) {
        if (obj === undefined || obj === null || obj.toString() === '0' || obj.toString() === 'NaN') {
            return '';
        }

        return obj.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
}