﻿export class Customer {
    public Id: number;
    public UserId: number;
    public LastName: string;
    public FirstName: string;
    public MiddleName: string;
    public FullName: string;
    public Dependents: number;
    public DateOfBirth: Date;
    public DateOfBirthStr: string;
    public Gender: number;
    public GenderName: number;
    public CivilStatus: number;
    public CivilStatusName: string;
    public Phone: string;
    public Email: string;
    public Address: string;
    public Income: number;
    public MonthlyIncome: number;
    public MonthlyExpenses: number;
    public BankRelationships: string;
    public TimeToCall: string;
    public Status: number;
    public CreateDate: Date;
    public RegionId: number;
    public RegionName: string;
    public CityId: number;
    public CityName: string;
    public SourceOfIncome: string;
    public SalaryRangeId: number;
    public PreferredLenderId: number;
    public Type: number;
    public Citizenship: number;
    public CitizenshipName: number;
    public PlaceOfBirth: string;
    public Residence: string;
    public YearsinAddress: number;
    public ResidenceStatus: number;
    public ResidenceStatusName: number;
    public Employment: number;
    public EmploymentStr: string;
    public EmployerName: string;
    public StatusOfEmployment: number;
    public StatusOfEmploymentStr: string;
    public AddressOfEmployer: string;
    public OfficeNumber: string;
    public NumberOfYearsWithEmployer: number;
    public BusinessName: string;
    public BusinessAddress: string;
    public ContactNumber: string;
    public Position: string;
    public NatureOfBusiness: string;
    public YearsinBusiness: number;
    public PreferedLender: string;
    public MonthlySalary: number;
}