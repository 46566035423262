import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';


export interface DialogDataInfo {
    message: string;
}

@Component({
    selector: 'app-dialog-info',
    templateUrl: './dialog-info.component.html'
})

export class DialogInfoComponent {

    constructor(
        public dialogRef: MatDialogRef<DialogInfoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogDataInfo) { }

    public onNoClick(): void {
        this.dialogRef.close();
    }

}
