﻿import { DatePipe } from "@angular/common";
import { PropertyBindingType } from "@angular/compiler";

export class SearchCondition {
    public FromDateStr: string;
    public ToDateStr: string;
    public FromDate: Date;
    public ToDate: Date;
    public Keyword: string;
    public PageSize: number;
    public PageIndex: number;
    public UserName: string;
    public Key: string;
    constructor() {
        this.Keyword = "";
        this.FromDateStr = "";
        this.ToDateStr = "";
        this.PageIndex = 1;
        this.PageSize = 15;
    }
}

// Model for search dashboard
export class SearchFormModel {
    public FromDate: string;
    public ToDate: string;
    public CityId: number;
    public AssigneeId: number;
}
export class SearchFormPendingModel extends SearchCondition {
    public BrandId: number;
    public AssigneeId: number;
    public Status: number;
    public ActionType: number;
    public PageId: number;

    constructor() {
        super();
        this.BrandId = 0;
        this.AssigneeId = 0;
        this.Keyword = "";
        this.ActionType = null;
        this.Status = null;
        this.PageId = 1;
    }

}

export class SearchInquiry extends SearchCondition {
    public ActionType: number;
    public Status: number;
}