﻿export class DealerLoginInfo {
    public UserName: string;
    public Status: number;
    public ActivateCode: string;
    public DisplayName: string;
    public Phone: string;
    public DealerId: number;
    public IsRoleInsurance: boolean;
    public PartnerType: number;
    public TimeLogin: number;
}