﻿import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from "rxjs";
import { map, catchError } from 'rxjs/operators';

import { CityModel } from '../bsl/model/city-model';
import { StringHelper } from '../bsl/stringHelper';

@Injectable({
    providedIn: 'root'
})

export class CityService {

    constructor(private httpLient: HttpClient) { }

    //Get city by account
    public getAllCity(username: string, key: string): Observable<CityModel[]> {
        var route = StringHelper.buildQueryStrings({ username: username, key: key });
        return this.httpLient.post<CityModel>("../Dealer/GetCityInfo" + route, null).pipe(
            map(
                (item: any) => {
                    return item;
                }),
            catchError(this.handleError)
        );
    }

    // Get all city
    public getAllCityV2(username: string, key: string): Observable<CityModel[]> {
        var route = StringHelper.buildQueryStrings({ username: username, key: key });
        return this.httpLient.post<CityModel>("../Dealer/GetAllCityInfo" + route, null).pipe(
            map(
                (item: any) => {
                    return item;
                }),
            catchError(this.handleError)
        );
    }

    // Handler error for http
    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.log(error.error);
        }
        // return an observable with a user-facing error message
        return throwError(
            'Something bad happened; please try again later.');
    };
}