﻿import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { map, catchError, filter } from 'rxjs/operators';
import { Observable, throwError } from "rxjs";
import { Router } from '@angular/router';
import { DealerAccountInfo, DealerUpdateModel } from './model/dealer-model';
import { DealerLoginInfo } from './model/dealer-login-info';
import { ConstLeads } from '../utils/const.leads';
import { ResponseData } from './model/response-data';
import { DealerSummary } from './model/dashboard-model';
import { BrandModelSelling } from './model/account-setting';
import { SearchFormPendingModel } from './model/search-model';
import { StringHelper } from '../bsl/stringHelper';
import { environment } from '../../environments/environment';
// import { time } from 'console';
// import { E } from '@angular/core/src/render3';

@Injectable({
    providedIn: 'root'
})

export class DealerService {

    public loginKey = "LeadPlatformLoginInfo";

    constructor(private httpClient: HttpClient,
        private router: Router,
        private constLeads: ConstLeads) { }

    // Get user by username & pass
    public getDealerByUsernamePass(userName: string, password: string): Observable<ResponseData> {
        var route = StringHelper.buildQueryStrings({ username: userName, pass: password });
        return this.httpClient.post<ResponseData>("../Dealer/Authenticate" + route, null)
            .pipe(
                map((item: any) => {
                    return item;
                }),
                catchError(this.handleError)
            );
    };

    // Get user by username & pass
    public getDealerByActivatedCode(activatedCode: string, type: number): Observable<ResponseData> {
        var route = StringHelper.buildQueryStrings({ type: type, activeCode: activatedCode });
        return this.httpClient.post<ResponseData>("../Dealer/GetByActiveCode" + route, null)
            .pipe(
                map((item: any) => {
                    return item;
                }),
                catchError(this.handleError)
            );
    };

    // Get change pass for active & forget pass
    public updateChangePass(type: number, activeCode: string, password: string): Observable<ResponseData> {
        var route = StringHelper.buildQueryStrings({ type: type, activeCode: activeCode, password: password });
        return this.httpClient.post<ResponseData>("../Dealer/UpdateChangePass" + route, null)
            .pipe(
                map((item: any) => {
                    return item;
                }),
                catchError(this.handleError)
            );
    };

    // Change pass only
    public updateChangePassOnly(userName: string, key: string, currentPass: string, password: string): Observable<ResponseData> {
        var route = StringHelper.buildQueryStrings({ userName: userName, key: key, currentPass: currentPass, password: password });
        return this.httpClient.post<ResponseData>("../Dealer/UpdateChangePassOnly" + route, null)
            .pipe(
                map((item: any) => {
                    return item;
                }),
                catchError(this.handleError)
            );
    };

    // Get user by username & pass
    public getDealerByKey(userName: string, key: string): Observable<DealerAccountInfo> {
        var route = StringHelper.buildQueryStrings({ username: userName, key: key });
        return this.httpClient.post<DealerAccountInfo>("../Dealer/GetDealerByKey" + route, null)
            .pipe(
                map((item: any) => {
                    return item;
                }),
                catchError(this.handleError)
            );
    };

    // Get user by username & pass
    public getDealerById(userName: string, key: string): Observable<ResponseData> {
        var route = StringHelper.buildQueryStrings({ username: userName, key: key });
        return this.httpClient.post<ResponseData>("../Dealer/GetDealerById" + route, null)
            .pipe(
                map((item: any) => {
                    return item;
                }),
                catchError(this.handleError)
            );
    };

    //Check all other page with authorization
    public async verifyAuthen(): Promise<boolean> {
        //1. Get info from localStorage
        let dealerInfo = localStorage.getItem(this.loginKey);
        if (dealerInfo == null) return false;

        //2. Get dealer info from DB and compare with local storage
        let parseDealer = JSON.parse(dealerInfo);
        let dealerInfoDb = <DealerAccountInfo>await this.getDealerByKey(parseDealer.UserName, parseDealer.ActivateCode).toPromise();
        if (dealerInfoDb == null) return false;
        //3. Check lại time out session 
        else {
            var timeout = environment.timeoutSession * 60 * 1000; //time out = min * 60s * 1000 milisecond
            // console.log('timeoutSession: ', environment.timeoutSession);
            var now = new Date().getTime(); //lấy time hiện tại
            //console.log('parseDealer', parseDealer);
            if (parseDealer.TimeLogin != null && parseDealer.TimeLogin != undefined) {
                if (now - timeout > parseDealer.TimeLogin) // nhỏ hơn thì đã time out
                    return false
                else {
                    parseDealer.TimeLogin = new Date().getTime();
                    localStorage.clear();

                    localStorage.setItem(this.loginKey, JSON.stringify(parseDealer));
                    return true;
                }
            }
            else
                return false;
        }
    };

    // Check dealer is primary
    public async isPrimaryAccount(): Promise<boolean> {
        //1. Get info from localStorage
        let dealerInfo = localStorage.getItem(this.loginKey);
        if (dealerInfo == null) return false;

        //2. Get dealer info from DB and compare with local storage
        let parseDealer = JSON.parse(dealerInfo);
        let dealerInfoDb = <DealerAccountInfo>await this.getDealerByKey(parseDealer.UserName, parseDealer.ActivateCode).toPromise();
        if (dealerInfoDb != null && dealerInfoDb.IsPrimary) return true;
        return false;
    };

    // Sign out application
    public logout(): void {
        localStorage.removeItem(this.loginKey);
        this.constLeads.resetLogin();
        this.router.navigate(['/']);
    };

    // Sign out application not go to login page
    public logoutNotRedirect(): void {
        localStorage.removeItem(this.loginKey);
    };

    // Get Dealer info from cookie
    public getLoginInfo(): DealerLoginInfo {
        let dealerInfo = new DealerLoginInfo();
        let cookieInfo = localStorage.getItem(this.loginKey);
        //console.log('cookieInfo', cookieInfo)
        if (cookieInfo != null && cookieInfo != undefined) {
            let dealerObj = JSON.parse(cookieInfo);
            // console.log('dealerObj', dealerObj);
            if (dealerObj != null && dealerObj != undefined) {
                dealerInfo.ActivateCode = dealerObj.ActivateCode;
                dealerInfo.DisplayName = dealerObj.DisplayName;
                dealerInfo.Phone = dealerObj.Phone;
                dealerInfo.Status = dealerObj.Status;
                dealerInfo.UserName = dealerObj.UserName;
                dealerInfo.DealerId = dealerObj.DealerId;
                dealerInfo.IsRoleInsurance = dealerObj.IsRoleInsurance;
                dealerInfo.PartnerType = dealerObj.PartnerType;
                dealerInfo.TimeLogin = dealerObj.TimeLogin != undefined && dealerObj.TimeLogin != null ? dealerObj.TimeLogin : new Date().getTime();

                //console.log('set out session: ', dealerInfo);
                if (dealerInfo.DisplayName != null && dealerInfo.DisplayName != undefined && dealerInfo.DisplayName != '' && this.verifyAuthen()) {
                    localStorage.clear();
                    localStorage.setItem(this.loginKey, JSON.stringify(dealerInfo)); // set lại time login vì thằng getLoginInfo() gọi ở nhiều nơi
                }
            }

            return dealerInfo;
        }

        return null;
    }

    // Create feedback
    public createFeedback(userName: string, key: string, feedbackContent: string): Observable<ResponseData> {
        var route = StringHelper.buildQueryStrings({ username: userName, key: key, feedbackContent: feedbackContent });
        return this.httpClient.post<ResponseData>("../Dealer/Feedback" + route, null)
            .pipe(
                map((item: any) => {
                    return item;
                }),
                catchError(this.handleError)
            );
    };

    // Get dealer dasboard
    public getListDashboard(userName: string, key: string, fromDate: string, toDate: string, cityId: number, assigneeId: number, pageIndex: number): Observable<ResponseData> {
        var route = StringHelper.buildQueryStrings({ username: userName, key: key, fromDate: fromDate, toDate: toDate, cityId: cityId, assigneeId: assigneeId, pageIndex: pageIndex });
        return this.httpClient.post<ResponseData>("../Dealer/GetDashboard" + route, null)
            .pipe(
                map((item: any) => {
                    return item;
                }),
                catchError(this.handleError)
            );
    };

    // Get dealer dasboard export excel
    public getListDashboardExport(userName: string, key: string, fromDate: string, toDate: string, cityId: number, assigneeId: number): Observable<ResponseData> {
        var route = StringHelper.buildQueryStrings({ username: userName, key: key, fromDate: fromDate, toDate: toDate, cityId: cityId, assigneeId: assigneeId });
        return this.httpClient.post<ResponseData>("../Dealer/GetDashboardExport" + route, null)
            .pipe(
                map((item: any) => {
                    return item;
                }),
                catchError(this.handleError)
            );
    };
    // Get list lead
    public getListLeads(searchModel: SearchFormPendingModel): Observable<ResponseData> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.httpClient.post<ResponseData>("../Dealer/GetListLeads", searchModel)
            .pipe(
                map((item: any) => {
                    return item;
                }),
                catchError(this.handleError)
            );
    };
    //export lead
    public doExportLead(searchModel: SearchFormPendingModel): Observable<ResponseData> {
        return this.httpClient.post<ResponseData>("../Dealer/ExportLeads", searchModel)
            .pipe(
                map((item: any) => {
                    return item;
                }),
                catchError(this.handleError)
            );
    };
    //#region Pending
    // Get data for cbo on search
    public initPendingSearchData(): Observable<ResponseData> {
        return this.httpClient.post<ResponseData>("../Dealer/InitPendingSearchData", null)
            .pipe(
                map((item: any) => {
                    return item;
                }),
                catchError(this.handleError)
            );
    };
    // Get dealer pending status
    public getLeadsByPendingStatus(searchModel: SearchFormPendingModel): Observable<ResponseData> {
        return this.httpClient.post<ResponseData>("../Dealer/GetLeadsByStatusPending", searchModel)
            .pipe(
                map((item: any) => {
                    return item;
                }),
                catchError(this.handleError)
            );
    };

    // Get dealer pending status for export
    public getLeadsByPendingStatusExport(userName: string, key: string, keyword: string, fromDate: string, toDate: string, brandId: number, assigneeId: number): Observable<ResponseData> {
        var route = StringHelper.buildQueryStrings({ username: userName, key: key, keyword: keyword, fromDate: fromDate, toDate: toDate, brandId: brandId, assigneeId: assigneeId });
        return this.httpClient.post<ResponseData>("../Dealer/GetLeadsByStatusPendingExport" + route, null)
            .pipe(
                map((item: any) => {
                    return item;
                }),
                catchError(this.handleError)
            );
    };

    // Update status pending
    public updateStatusPending(username: string, key: string, keyLeads: string, id: number, status: number, note: string): Observable<ResponseData> {
        var route = StringHelper.buildQueryStrings({ username: username, key: key, keyLeads: keyLeads, id: id, status: status, note: note });
        return this.httpClient.post<ResponseData>("../Dealer/UpdateStatusPending" + route, null)
            .pipe(
                map((item: any) => {
                    return item;
                }),
                catchError(this.handleError)
            );
    };

    //#endregion

    //#region hot lead
    public initHotLeadSearchData(): Observable<ResponseData> {
        return this.httpClient.post<ResponseData>("../Dealer/InitHotLeadSearchData", null)
            .pipe(
                map((item: any) => {
                    return item;
                }),
                catchError(this.handleError)
            );
    };
    // Get dealer contacted status
    public getLeadsByContactedStatus(searchModel: SearchFormPendingModel): Observable<ResponseData> {
        return this.httpClient.post<ResponseData>("../Dealer/GetLeadsByStatusContacted", searchModel)
            .pipe(
                map((item: any) => {
                    return item;
                }),
                catchError(this.handleError)
            );
    };

    // Get dealer contacted status for export
    public getLeadsByContactedStatusExport(userName: string, key: string, keyword: string, fromDate: string, toDate: string, brandId: number, assigneeId: number): Observable<ResponseData> {
        var route = StringHelper.buildQueryStrings({ username: userName, key: key, keyword: keyword, fromDate: fromDate, toDate: toDate, brandId: brandId, assigneeId: assigneeId });
        return this.httpClient.post<ResponseData>("../Dealer/GetLeadsByStatusContactedExport" + route, null)
            .pipe(
                map((item: any) => {
                    return item;
                }),
                catchError(this.handleError)
            );
    };

    // Update status contacted
    public updateStatusContacted(username: string, key: string, keyLeads: string, id: number, status: number, note: string): Observable<ResponseData> {
        var route = StringHelper.buildQueryStrings({ username: username, key: key, keyLeads: keyLeads, id: id, status: status, note: note });
        return this.httpClient.post<ResponseData>("../Dealer/UpdateStatusContacted" + route, null)
            .pipe(
                map((item: any) => {
                    return item;
                }),
                catchError(this.handleError)
            );
    };

    //#endregion

    //#region archived lead
    public initArchivedLeadSearchData(): Observable<ResponseData> {
        return this.httpClient.post<ResponseData>("../Dealer/InitArchivedLeadSearchData", null)
            .pipe(
                map((item: any) => {
                    return item;
                }),
                catchError(this.handleError)
            );
    };
    // Get dealer contacted status
    public getLeadsByOnboardedStatus(searchModel: SearchFormPendingModel): Observable<ResponseData> {
        return this.httpClient.post<ResponseData>("../Dealer/GetLeadsByStatusOnboarded", searchModel)
            .pipe(
                map((item: any) => {
                    return item;
                }),
                catchError(this.handleError)
            );
    };

    // Get dealer contacted status for export
    public getLeadsByOnboardedStatusExport(userName: string, key: string, keyword: string, fromDate: string, toDate: string, brandId: number, assigneeId: number): Observable<ResponseData> {
        var route = StringHelper.buildQueryStrings({ username: userName, key: key, keyword: keyword, fromDate: fromDate, toDate: toDate, brandId: brandId, assigneeId: assigneeId });
        return this.httpClient.post<ResponseData>("../Dealer/GetLeadsByStatusOnboardedExport" + route, null)
            .pipe(
                map((item: any) => {
                    return item;
                }),
                catchError(this.handleError)
            );
    };
    //#endregion
    // Create note
    public createNote(userName: string, key: string, leaderDealerId: number, contentNote: string): Observable<ResponseData> {
        var route = StringHelper.buildQueryStrings({ username: userName, key: key, leaderDealerId: leaderDealerId, contentNote: contentNote });
        return this.httpClient.post<ResponseData>("../Dealer/CreateNote" + route, null)
            .pipe(
                map((item: any) => {
                    return item;
                }),
                catchError(this.handleError)
            );
    };

    // Get all account by dealerId
    public getAllDealerAccount(userName: string, key: string, dealerId: number): Observable<ResponseData> {
        var route = StringHelper.buildQueryStrings({ username: userName, key: key, dealerId: dealerId });
        return this.httpClient.post<ResponseData>("../Dealer/GetAllSubDelaer" + route, null)
            .pipe(
                map((item: any) => {
                    return item;
                }),
                catchError(this.handleError)
            );
    };


    // Get user by username & pass
    public updateDealerAccount(username: string, key: string, profile: DealerAccountInfo, brandSettings: BrandModelSelling[]): Observable<ResponseData> {
        var model = new DealerUpdateModel();
        model.username = username;
        model.key = key;
        model.profile = profile;
        model.brandSettings = brandSettings;
        //console.log(JSON.stringify(model));
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.httpClient.post<ResponseData>("../Dealer/UpdateAccount", model)
            .pipe(
                map((item: any) => {
                    return item;
                }),
                catchError(this.handleError)
            );
    };

    // Handler error for http
    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);

            console.log(error.error);
        }
        // return an observable with a user-facing error message
        return throwError(
            'Something bad happened; please try again later.');
    };

    // Get user by username & pass
    public getSellerById(productId: number, userName: string, key: string): Observable<ResponseData> {
        var route = StringHelper.buildQueryStrings({ productId: productId, username: userName, key: key });
        return this.httpClient.post<ResponseData>("../Dealer/GetSellerById" + route, null)
            .pipe(
                map((item: any) => {
                    return item;
                }),
                catchError(this.handleError)
            );
    };

    //Import lead
    public doImport(importFile: File, userName: string, key: string): Observable<ResponseData> {
        const formData: FormData = new FormData();
        formData.append(importFile.name, importFile);
        formData.append("username", userName);
        formData.append("key", key);
        return this.httpClient.post<ResponseData>("../Dealer/ImportExcelLeadPending", formData)
            .pipe(
                map((item: any) => {
                    return item;
                }),
                catchError(this.handleError)
            );
    };
    //Get customer infomation
    public getCustomerDetailByPhoneAndEmail(phoneNumber: string, email: string, userName: string, key: string): Observable<ResponseData> {
        var route = StringHelper.buildQueryStrings({ phoneNumber: phoneNumber, email: email, username: userName, key: key });
        return this.httpClient.post<ResponseData>("../Dealer/GetCustomerInfo" + route, null)
            .pipe(
                map((item: any) => {
                    return item;
                }),
                catchError(this.handleError)
            );
    };
}
