export class Pager {

    public CurrentPage: number;
    public PageSize: number;
    public TotalItem: number;
    /**
     *
     */
    constructor() {
        this.CurrentPage = 1;
        this.TotalItem = 0;
        this.PageSize = 15;
    }

}