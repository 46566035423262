﻿export class LeadStatusModel {
    public type: number;
    public name: string;
    public value: number;

    constructor(value, name, type) {
        this.value = value;
        this.name = name;
        this.type = type;
    }
}