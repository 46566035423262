import { Directive, Input } from '@angular/core';

@Directive({
    selector: '[prevent-keys]',
    host: {
        '(keydown)': 'onKeyDown($event)'
    }
})
export class PreventTextboxSpaceDirective {

    constructor() { }

    @Input('prevent-keys') preventKeys;
    onKeyDown($event) {
        if (this.preventKeys && this.preventKeys.includes($event.keyCode)) {
            $event.preventDefault();
        } 
    }
}
