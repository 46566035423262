import { Component, Inject, HostListener } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';


export interface DialogDataConfirm {
    message: string;
    btnOkText: string;
    btnCancelText: string;
    isOkClick: boolean;
}

@Component({
    selector: 'app-dialog-info',
    templateUrl: './dialog-confirm.component.html'
})

export class DialogConfirmComponent {

    constructor(
        public dialogRef: MatDialogRef<DialogConfirmComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogDataConfirm) { }

    public onClick(): void {
        this.data.isOkClick = true;
        this.dialogRef.close(this.data.isOkClick);
        
    }

    public onNoClick(): void {
        this.data.isOkClick = false;
        this.dialogRef.close(this.data.isOkClick);
    }

    @HostListener("keydown.esc")
    public onEsc() {
        this.dialogRef.close();
    }

}
