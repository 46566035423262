import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ConstLeads } from '../../utils/const.leads';
import { DealerService } from '../../bsl/dealer.service';
import { first } from 'rxjs/operators';
import { SafePipe } from '../../utils/utils-string';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { DialogInfoComponent } from '../../views/partial/dialog/info/dialog-info.component';

@Component({
    selector: 'app-change-pass',
    templateUrl: './change-pass.component.html'
})

export class ChangePassComponent implements OnInit {

    public typeAction = 0;
    public textError = "";
    public textHeading = "";
    public submitted = false;
    public isFormError = false;
    private changeForm: FormGroup;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private constLeads: ConstLeads,
        public dialog: MatDialog,
        private titleService: Title,
        private dealerService: DealerService) { }

    public ngOnInit() {

        this.changeForm = this.formBuilder.group({
            password: ['', [Validators.required, Validators.minLength(6)]],
            confirmPassword: ['', Validators.required]
        });

        // set title page
        this.titleService.setTitle(this.constLeads.TitlePageChangePass);

        this.route.params.subscribe(params => {
            if (params != null) {
                let type = params["type"];
                let code = params["activedcode"];
                if (!isNaN(type)) {

                    this.dealerService.getDealerByActivatedCode(code, type).pipe(first()).subscribe(p => {
                        if (p != null) {
                            if (p.Success) {
                                this.textHeading = p.Message;
                            } else {
                                this.textError = p.Message;
                            }
                        } else {
                            this.textError = this.constLeads.ChangePass404;
                        }
                    });

                } else {
                    this.textError = this.constLeads.ChangePass404;
                }
            } else {
                this.textError = this.constLeads.ChangePass404;
            }
        });

    }

    public changePassword() {
        this.submitted = true;

        if (this.changeForm.invalid) { return; }
        let pass = this.changeForm.value.password;
        let confirmPass = this.changeForm.value.confirmPassword;
        if (pass != confirmPass) {
            this.isFormError = true;
            return;
        }

        this.isFormError = false;
        this.constLeads.ProgressValue = 90;
        this.route.params.subscribe(params => {
            if (params != null) {
                let type = params["type"];
                let code = params["activedcode"];
                if (!isNaN(type)) {
                    this.dealerService.updateChangePass(type, code, pass).pipe(first()).subscribe(p => {

                        setTimeout(() => {
                            if (p != null) {
                                if (p.Success) {

                                    const dialogRef = this.dialog.open(DialogInfoComponent, {
                                        width: '350px',
                                        data: { message: "Change password successful!!" }
                                    });

                                    dialogRef.afterClosed().subscribe(result => {
                                        this.router.navigate(['/']);
                                    });

                                } else {
                                    this.dialog.open(DialogInfoComponent, {
                                        width: '350px',
                                        data: { message: p.Message }
                                    });
                                }
                            }
                            this.constLeads.ProgressValue = 100;

                        }, 200);

                    });
                }
            }
        });
    }

}

