import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from "@angular/router";
import { ConstLeads } from "../utils/const.leads";
import { SearchInquiry } from "../bsl/model/search-model";
import { Observable } from "rxjs";
import { ResponseData } from "../bsl/model/response-data";
import { catchError, map } from "rxjs/operators";
import { throwError } from "rxjs/internal/observable/throwError";

@Injectable({
    providedIn: 'root'
})

export class InquiryService {
    constructor(
        private httpClient: HttpClient,
        private router: Router,
        private constLeads: ConstLeads
    ) { }

    public getList(searchModel: SearchInquiry): Observable<ResponseData> {
        return this.httpClient.post<ResponseData>("../InquiryManagement/getlist", searchModel)
            .pipe(
                map((item: any) => {
                    return item;
                }),
                catchError(this.handleError)
            );
    }

    public initSearch(): Observable<ResponseData> {
        return this.httpClient.post<ResponseData>("../InquiryManagement/InitSearch", null)
            .pipe(
                map((item: any) => {
                    return item;
                }),
                catchError(this.handleError)
            );
    }
    public updateStatus(model: any): Observable<ResponseData> {
        return this.httpClient.post<ResponseData>("../InquiryManagement/UpdateStatus", model).pipe(
            map((item: any) => {
                return item;
            }),
            catchError(this.handleError)
        );
    }
    // Handler error for http
    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.log(error.error);
        }
        // return an observable with a user-facing error message
        return throwError(
            'Something bad happened; please try again later.');
    };
}   
