﻿import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { NgxPaginationModule } from 'ngx-pagination';
import { LocationStrategy, HashLocationStrategy, PathLocationStrategy } from '@angular/common';
import {
    MatProgressBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatDialogModule
} from '@angular/material';
import { TagInputModule } from 'ngx-chips';
import { NgSelectModule } from '@ng-select/ng-select';

import { AuthGuard } from './utils/authorize/auth.guard';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { DealerService } from './bsl/dealer.service';
import { ConstLeads } from './utils/const.leads';;
import { AppComponent } from './app.component';
import { LoginComponent } from './views/login/login.component';
import { AccountSettingComponent } from './views/account-setting/account-setting.component';
import { PendingComponent } from './views/pending/pending.component';
import { ContactedComponent } from './views/contacted/contacted.component';
import { OnboardedComponent } from './views/onboarded/onboarded.component';
import { DialogInfoComponent } from './views/partial/dialog/info/dialog-info.component';
import { DialogConfirmComponent } from './views/partial/dialog/confirm/dialog-confirm.component';
import { UtilsString, SafePipe } from './utils/utils-string';
import { DatePipe } from '../../node_modules/@angular/common';
import { ExcelService } from './bsl/excel.service';
import { BrandService } from './bsl/brand.service';
import { ModelService } from './bsl/model.service';
import { ChangePassComponent } from './views/change-pass/change-pass.component';

import { PreventTextboxSpaceDirective } from './utils/directive/prevent-textbox-space.directive'//import { DialogConfirmComponent } from './views/partial/dialog/confirm/dialog-confirm.component';
import { ModalModule } from 'ngx-bootstrap/modal';;
import { InquiryComponent } from './views/inquiry/inquiry.component'
const appRoutes: Routes = [
    { path: '', component: LoginComponent },
    { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
    { path: 'account-setting', component: AccountSettingComponent, canActivate: [AuthGuard] },
    { path: 'pending', component: PendingComponent, canActivate: [AuthGuard] },
    { path: 'contacted', component: ContactedComponent, canActivate: [AuthGuard] },
    { path: 'archived', component: OnboardedComponent, canActivate: [AuthGuard] },
    { path: 'inquiry', component: InquiryComponent, canActivate: [AuthGuard] },
    { path: 'change-password/:type/:activedcode', component: ChangePassComponent },

    // otherwise redirect to home
    { path: '**', redirectTo: '/dashboard' }
];

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        DashboardComponent,
        AccountSettingComponent,
        PendingComponent,
        ContactedComponent,
        OnboardedComponent,
        DialogInfoComponent,
        DialogConfirmComponent,
        SafePipe,
        ChangePassComponent,
        PreventTextboxSpaceDirective,
        InquiryComponent],
    imports: [
        BrowserModule,
        HttpClientModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatNativeDateModule,
        FormsModule,
        CdkTableModule,
        CdkTreeModule,
        MatDatepickerModule,
        MatProgressBarModule,
        MatInputModule,
        MatIconModule,
        MatFormFieldModule,
        MatDialogModule,
        NgxPaginationModule,
        TagInputModule,
        NgSelectModule,
        ModalModule.forRoot(),
        RouterModule.forRoot(appRoutes, { useHash: true })
    ],
    providers: [{
        provide: LocationStrategy,
        useClass: PathLocationStrategy
    },
        AuthGuard,
        DealerService,
        ModelService,
        ConstLeads,
        Title,
        UtilsString,
        ExcelService,
        DatePipe,
        BrandService],
    bootstrap: [AppComponent],
    entryComponents: [AppComponent,
        DialogInfoComponent,
        DialogConfirmComponent]
})
export class AppModule { }
