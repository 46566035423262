﻿import { StringHelper } from "../stringHelper";
import { BrandModel } from "./brand-model";
import { BrandModelSelling } from "./account-setting";

export class DealerAccountInfo {
    Id: number
    IsPrimary: boolean;
    DealerId: number;
    Email: string;
    UserName: string;
    Status: number;
    CreatedDate: Date;
    CreatedDateSpan: number;
    LevelId: number;
    Phone: string;
    DisplayName: string;
    CityId: number;
    CarCondition: number;
    ListDetail: DealerAccountDetailInfo[];
    ListBrandAccount: BrandModel[];
}

export class DealerAccountDetailInfo {
    DealerAccountId: number
    BrandId: number;
    ModelId: number;
    VersionId: number;
    ProducedYearMin: number;
    ProducedYearMax: number;
    RegionId: number;
    CityId: number;
}


export class DealerAccountInfoViewer {
    public DealerAccountModel: DealerAccountInfo;
    public BrandModelView: string;
}
export class DealerUpdateModel {
    public username: string;
    public key: string;
    public profile: DealerAccountInfo;
    public brandSettings: BrandModelSelling[];
}