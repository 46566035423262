﻿import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DealerService } from '../../bsl/dealer.service';
import { ConstLeads } from '../const.leads';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private dealService: DealerService,
        private constLeads: ConstLeads) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        var isLogged = await this.dealService.verifyAuthen();
        if (isLogged) return true;

        this.dealService.logoutNotRedirect();
        this.constLeads.resetLogin();
        this.router.navigate(['/'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}