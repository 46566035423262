﻿export class BrandModelSelling {
    constructor(brandId: number, modelId: number, minYear: number, maxYear: number) {
        this.BrandId = brandId;
        this.ModelId = modelId;
        this.MinYear = minYear;
        this.MaxYear = maxYear;
    }
    public BrandId: number;
    public ModelId: number;
    public MinYear: number;
    public MaxYear: number;
}