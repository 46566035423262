import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { DealerService } from '../../bsl/dealer.service';
import { InquiryModel } from '../../bsl/model/inquiry-model';
import { LeadsModel } from '../../bsl/model/leads-model';
import { Pager } from '../../bsl/model/pager';
import { SearchInquiry } from '../../bsl/model/search-model';
import { InquiryService } from '../../services/inquiry.service';
import { CommonFn } from '../../utils/commonfn';
import { ConstLeads } from '../../utils/const.leads';
import { LeadStatus } from '../../utils/enums/lead-status.enum';
import { ErrorCodeEnum } from '../../utils/enums/leads-type.enum';
import { UtilsString } from '../../utils/utils-string';
import { DialogConfirmComponent } from '../partial/dialog/confirm/dialog-confirm.component';
import { DialogInfoComponent } from '../partial/dialog/info/dialog-info.component';

@Component({
  selector: 'app-inquiry',
  templateUrl: './inquiry.component.html',
  providers: [
    DatePipe
  ]
})
export class InquiryComponent implements OnInit {
  public listInquiry: InquiryModel[];
  public dealerInfo = this.dealerService.getLoginInfo();
  public listStatus: any;
  public listActionType: any;
  public searchParam: SearchInquiry;
  public pager: Pager;
  public itemPergage: number = 0;
  public detailModel: InquiryModel;
  public showRejectReasonForm = false;
  public rejectReason = '';
  public rejectReasonOther = '';
  // public rejectReasonForm = { isShow: false, reason: "", reasonOther: '', submitted: false, leadDealerId: 0, status: 0, keyLeads: '' };
  constructor(
    private dealerService: DealerService,
    private inquiryService: InquiryService,
    private titleService: Title,
    private constLeads: ConstLeads,
    private router: Router,
    public dialog: MatDialog,
    private activeRouter: ActivatedRoute,
    private utilsString: UtilsString,
    private datePipe: DatePipe,
  ) {
    this.searchParam = new SearchInquiry();
    this.searchParam.PageSize = this.constLeads.PageItem;
    this.pager = new Pager();
  }

  ngOnInit() {
    // check authen
    if (this.dealerInfo == null || this.dealerInfo.DealerId <= 0) {
      this.constLeads.resetLogin();
      this.router.navigate(['/'], { queryParams: { returnUrl: this.constLeads.PendingLink } });
      return false;
    }
    this.constLeads.LoginDisplayName = this.dealerInfo.DisplayName;
    this.constLeads.LoginPhone = this.dealerInfo.Phone;
    this.constLeads.innitInquiryManagement();
    this.titleService.setTitle("Inquiry Management");
    //get search data
    this.initSearch();
  }

  public initSearch() {
    this.inquiryService.initSearch().subscribe(p => {
      if (p != undefined && p.Success) {
        this.listStatus = p.Data.ListStatus;
        this.listActionType = p.Data.ListActionType;
        this.searchParam.ActionType = this.listActionType[0].Key;
        this.searchParam.UserName = this.dealerInfo.UserName;
        this.searchParam.Key = this.dealerInfo.ActivateCode;
        // this.listStatus.splice(0,0,{Key:0, Value :'--All status--'});
        this.getData(1);
      }
    });
  }
  // get data 
  public getData(pageIndex: number) {
    this.constLeads.ProgressValue = 70;
    this.searchParam.PageIndex = pageIndex;
    //date format
    if (this.searchParam.FromDate && this.searchParam.FromDate != null) {
      this.searchParam.FromDateStr = this.utilsString.ConvertDate2str(new Date(this.searchParam.FromDate));
    }

    if (this.searchParam.ToDate && this.searchParam.ToDateStr != null) {
      this.searchParam.ToDateStr = this.utilsString.ConvertDate2str(new Date(this.searchParam.ToDate));
    }

    this.inquiryService.getList(this.searchParam).subscribe(rs => {
      if (rs && rs.Success) {
        if (rs.Data) {
          this.pager = rs.Data.Pager as Pager;
          this.listInquiry = rs.Data.ListInquiry;
          this.itemPergage = this.getItemPerPage();
          this.constLeads.ProgressValue = 99;
        }
        if (rs.ErrorCode != null && rs.ErrorCode == ErrorCodeEnum.AccountLocked) {
          this.constLeads.resetLogin();
          this.router.navigate(['/'], { queryParams: { returnUrl: this.constLeads.InquiryLink } });
          return;
        }
      }
      this.constLeads.ProgressValue = 100;
    });
  }
  //caculate item per page
  public getItemPerPage() {
    if (this.pager.TotalItem <= 0) return 0;
    if (this.pager.TotalItem <= this.pager.PageSize || this.pager.TotalItem <= this.pager.PageSize * this.pager.CurrentPage)
      return this.pager.TotalItem;
    return this.pager.PageSize * this.pager.CurrentPage;
  };

  //Change status
  public doChangeStatus() {
    if (this.detailModel.Id > 0 && this.detailModel.Status > 0) {
      const param = {
        Id: this.detailModel.Id,
        UserName: this.searchParam.UserName,
        Key: this.searchParam.Key,
        Note: this.detailModel.Note,
        Status: this.detailModel.StatusFake,
        LeadsKey: this.detailModel.LeadsKey
      };
      //call server
      this.inquiryService.updateStatus(param).subscribe(rs => {
        if (rs && rs.Success) {
          this.getData(1);
        } else {
          // revert status
          this.detailModel.StatusFake = this.detailModel.Status;
          if (rs.ErrorCode != null && rs.ErrorCode == ErrorCodeEnum.AccountLocked) {
            this.constLeads.resetLogin();
            this.router.navigate(['/'], { queryParams: { returnUrl: this.constLeads.PendingLink } });
            return;
          }
          this.ShowMessageDialog(rs.Message);
        }
      });
    } else {
      this.ShowMessageDialog('Data is invalid!');
    }
  }

  //Show dialog message
  public ShowMessageDialog(message: string) {
    this.dialog.open(DialogInfoComponent, {
      width: '350px',
      data: { message: message }
    });
  }

  public changeStatus(item: InquiryModel) {
    this.detailModel = item;
    if (this.dealerInfo != null) {
      //change to reject
      if (item.StatusFake == LeadStatus.Rejected) {
        this.showRejectReasonForm = true;
        // this.openRejectForm(item.Id, item.LeadsKey, status); // open reason form
      }
      else {
        const dialogRef = this.dialog.open(DialogConfirmComponent, {
          width: '350px',
          data: {
            message: this.constLeads.ConfirmContentStatus,
            btnOkText: 'Ok',
            btnCancelText: 'Cancel'
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.doChangeStatus();
          } else {
            // revert status
            this.detailModel.StatusFake = this.detailModel.Status;
            this.detailModel = null;
          }
        });
      }
    }
  }

  // Open reject reason
  public closeRejectForm() {
    this.showRejectReasonForm = false;
    this.detailModel.StatusFake = this.detailModel.Status;
    this.detailModel = null;
  }

  //On change for reason combobox
  public submitRejectReason() {
    if (this.rejectReason.trim() == '') return;
    this.detailModel.Note = this.rejectReason;
    if (this.rejectReason == 'Other') {
      if (this.rejectReasonOther.trim() == '') return;
      this.detailModel.Note = this.rejectReasonOther;
    }
    this.showRejectReasonForm = false;
    this.doChangeStatus();
    }

    formatNumber(obj: number) {
        if (obj === undefined || obj === null || obj.toString() === '0' || obj.toString() === 'NaN') {
            return '';
        }

        return obj.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
}