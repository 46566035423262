import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { DialogInfoComponent } from '../../views/partial/dialog/info/dialog-info.component';
import { ConstLeads } from '../../utils/const.leads';
import { DealerService } from '../../bsl/dealer.service';
import { DealerAccountInfo, DealerAccountInfoViewer } from '../../bsl/model/dealer-model';
import { first, retry } from 'rxjs/operators';
import { CityModel } from '../../bsl/model/city-model';
import { CityService } from '../../bsl/city.service';
import { BrandService } from '../../bsl/brand.service';
import { BrandModel } from '../../bsl/model/brand-model';
import { ModelService } from '../../bsl/model.service';
import { Observable } from 'rxjs';
import { BrandModelSelling } from '../../bsl/model/account-setting';
import { ErrorCodeEnum } from '../../utils/enums/leads-type.enum';
import * as $ from 'jquery';

@Component({
    selector: 'app-account-setting',
    templateUrl: './account-setting.component.html'
})
export class AccountSettingComponent implements OnInit {

    public stringTemplateModelByBrandId: string;
    public brandModel: BrandModel[];
    public brandModelPool: string;
    public brandModelSelect: BrandModel[];
    public cityModel: CityModel[];
    public dealerInfo: DealerAccountInfoViewer;
    public formSetting = { Submitted: false, IsErrorFormatEmail: false, IsErrorFormatPhone: false, IsError: false, Id: 0, Email: "", UserName: "", Phone: "", DisplayName: "", CityId: 0, CarCondition: 0 };
    public formChangePass = { IsError: false, IsOpen: false, Submitted: false, OldPass: "", NewPass: "", Confirm: "" };
    public textError = '';
    constructor(
        private constLeads: ConstLeads,
        private dealerService: DealerService,
        private cityService: CityService,
        private brandService: BrandService,
        private modelService: ModelService,
        private titleService: Title,
        public dialog: MatDialog,
        private activeRouter: ActivatedRoute,
        public router: Router) {

        this.formSetting.Id = 0;
        this.formSetting.Email = "";
        this.formSetting.UserName = "";
        this.formSetting.Phone = "";
        this.formSetting.DisplayName = "";
        this.stringTemplateModelByBrandId = "";

        this.formChangePass.OldPass = "";
        this.formChangePass.NewPass = "";
        this.formChangePass.Confirm = "";

        // refresh url
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        }

        this.router.events.subscribe((evt) => {
            if (evt instanceof NavigationEnd) {
                // trick the Router into believing it's last link wasn't previously loaded
                this.router.navigated = false;
                // if you need to scroll back to top, here is the right place
                window.scrollTo(0, 0);
            }
        });
    }

    public ngOnInit() {
        // Setting common page
        this.constLeads.innitAccountSetting()

        // get login info
        let dealerInfo = this.dealerService.getLoginInfo();
        if (dealerInfo != null) {
            this.constLeads.LoginDisplayName = dealerInfo.DisplayName;
            this.constLeads.LoginPhone = dealerInfo.Phone;

            // set title page
            this.titleService.setTitle(this.constLeads.TitleAccountPage);

            this.getCityCombobox();

            this.getBrandCombobox();

            this.getDealerInfo();

        } else {
            this.constLeads.resetLogin();
            this.router.navigate(['/'], { queryParams: { returnUrl: this.constLeads.AccountSettingLink } });
        }
    }

    public getDealerInfo() {

        setTimeout(() => {

            this.constLeads.ProgressValue = 90;

            let dealerInfoTemp = this.dealerService.getLoginInfo();
            if (dealerInfoTemp != null) {

                let username = dealerInfoTemp.UserName;
                let key = dealerInfoTemp.ActivateCode;

                this.dealerService.getDealerById(username, key).pipe(first()).subscribe(p => {

                    if (p != null) {
                        if (p.Success) {
                            this.dealerInfo = <DealerAccountInfoViewer>p.Data;
                            if (this.dealerInfo != null) {

                                this.formSetting.Id = this.dealerInfo.DealerAccountModel.Id;
                                this.formSetting.Email = this.dealerInfo.DealerAccountModel.Email;
                                this.formSetting.UserName = this.dealerInfo.DealerAccountModel.UserName;
                                this.formSetting.Phone = this.dealerInfo.DealerAccountModel.Phone;
                                this.formSetting.DisplayName = this.dealerInfo.DealerAccountModel.DisplayName;
                                this.formSetting.CityId = this.dealerInfo.DealerAccountModel.CityId;
                                this.formSetting.CarCondition = this.dealerInfo.DealerAccountModel.CarCondition;
                                this.brandModelSelect = this.dealerInfo.DealerAccountModel.ListBrandAccount;

                                setTimeout(() => {
                                    if (this.dealerInfo.BrandModelView != null && this.dealerInfo.BrandModelView != '') {
                                        $(".content-model-view").html(this.dealerInfo.BrandModelView);

                                        // change select model 
                                        $(".box-account .item-model .model-combo select.drop").each(function () {
                                            $(this).change(function () {

                                                let self = $(this);
                                                let modelId = parseInt($(this).val());

                                                let modelSelected = $(self).closest(".item-model").find(".sub-model-item #box-model-item-" + modelId).length;
                                                if (modelId > 0 && modelSelected <= 0) {

                                                    $.ajax({
                                                        method: "POST",
                                                        url: "../Dealer/GetItemModelTemplateByBrand",
                                                        data: { modelId: modelId },
                                                        success: function (p) {
                                                            if (p != null && p != undefined && p.Success) {
                                                                $(self).closest(".item-model").find(".sub-model-item").append(p.Data);
                                                                $(self).closest(".item-model").find(".model-combo select.drop").val("0");

                                                                // binding remove click
                                                                $(self).closest(".item-model").find(".sub-model-item").find("#box-model-item-" + modelId).find(".delete-model").click(function () {
                                                                    $(this).parent().remove();
                                                                });
                                                            }
                                                        }
                                                    });

                                                } else {
                                                    $(self).closest(".item-model").find(".model-combo select.drop").val("0");
                                                }

                                            });
                                        });
                                        // remove models
                                        $(".sub-model-item .box-model-item .delete-model").each(function () {
                                            $(this).click(function () {
                                                $(this).parent().remove();
                                            });
                                        });
                                    }
                                }, 200);
                            }
                        } else {
                            if (p.ErrorCode != null && p.ErrorCode == ErrorCodeEnum.AccountLocked) {
                                this.constLeads.resetLogin();
                                this.router.navigate(['/'], { queryParams: { returnUrl: this.constLeads.AccountSettingLink } });
                            }
                        }
                    }
                    this.constLeads.ProgressValue = 100;

                });
            }

        }, 200);

    }

    public onRemovedBrand(tag: BrandModel): void {
        if (tag.BrandId > 0) {
            $(".model-brand-" + tag.BrandId).remove();
        }
    }

    public onAddBrand(tag: BrandModel): void {
        if (tag.BrandId > 0) {
            let dealerInfo = this.dealerService.getLoginInfo();
            if (dealerInfo != null) {
                let username = dealerInfo.UserName;
                let key = dealerInfo.ActivateCode;

                this.modelService.getAll(username, key, tag.BrandId).subscribe(p => {
                    if (p != null && p != undefined) {
                        if (p.Success) {
                            $(".content-model-view").prepend(p.Data);
                            $(".model-brand-" + tag.BrandId).find(".model-combo select.drop").on("change", function () {
                                let modelId = parseInt($(this).val());
                                let modelSelected = $(".model-brand-" + tag.BrandId).find(".sub-model-item #box-model-item-" + modelId).length;

                                if (modelId > 0 && modelSelected <= 0) {

                                    $.ajax({
                                        method: "POST",
                                        url: "../Dealer/GetItemModelTemplateByBrand",
                                        data: { modelId: modelId },
                                        success: function (p) {
                                            if (p != null && p != undefined && p.Success) {
                                                $(".model-brand-" + tag.BrandId).find(".sub-model-item").append(p.Data);
                                                $(".model-brand-" + tag.BrandId).find(".model-combo select.drop").val("0");

                                                // binding remove click
                                                $(".model-brand-" + tag.BrandId).find(".sub-model-item").find("#box-model-item-" + modelId).find(".delete-model").click(function () {
                                                    $(this).parent().remove();
                                                });
                                            }
                                        }
                                    });

                                } else {
                                    $(".model-brand-" + tag.BrandId).find(".model-combo select.drop").val("0");
                                }
                            });
                        } else {
                            if (p.ErrorCode != null && p.ErrorCode == ErrorCodeEnum.AccountLocked) {
                                this.constLeads.resetLogin();
                                this.router.navigate(['/'], { queryParams: { returnUrl: this.constLeads.AccountSettingLink } });
                                return;
                            }
                        }
                    }

                });
            } else {
                this.constLeads.resetLogin();
                this.router.navigate(['/'], { queryParams: { returnUrl: this.constLeads.AccountSettingLink } });
            }
        }
    }

    // Open form change pass
    public openChangePass() {
        this.formChangePass.IsOpen = true;
    }

    // close form change pass
    public closeChangePass() {
        this.formChangePass.IsOpen = false;
        this.formChangePass.NewPass = '';
        this.formChangePass.OldPass = '';
        this.formChangePass.Confirm = '';
        this.formChangePass.IsError = false;
        this.formChangePass.Submitted = false;
    }

    //Cancel form 
    public cancelForm() {
        this.router.navigate(['/account-setting']);
    }

    // Submit form 
    public saveChange() {

        let dealerInfo = this.dealerService.getLoginInfo();
        if (dealerInfo != null) {
            let isValid = true;
            //1. Validate form 
            this.formSetting.Submitted = true;

            if (this.formSetting.DisplayName != null && $.trim(this.formSetting.DisplayName) == "") {
                this.formSetting.DisplayName = "";
            } else {
                this.formSetting.DisplayName = $.trim(this.formSetting.DisplayName);
            }

            if (this.formSetting.Email != null && $.trim(this.formSetting.Email) == "") {
                this.formSetting.Email = "";
            }

            if (this.formSetting.Phone != null && $.trim(this.formSetting.Phone) == "") {
                this.formSetting.Phone = "";
            }

            if (this.formSetting.DisplayName == null || this.formSetting.DisplayName == "") {
                isValid = false;
            }

            if (this.formSetting.Email == null || this.formSetting.Email == "") {
                isValid = false;
            } else {
                const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
                if (!emailPattern.test(this.formSetting.Email)) {
                    isValid = false;
                    this.formSetting.IsErrorFormatEmail = true;
                } else {
                    this.formSetting.IsErrorFormatEmail = false;
                }
            }

            if (this.formSetting.Phone == null || this.formSetting.Phone == "") {
                isValid = false;
            } else {
                const phonePattern = /^0-?\d{1,3}-?\d{3}-?\d{4}$/;
                if (!phonePattern.test(this.formSetting.Phone)) {
                    isValid = false;
                    this.formSetting.IsErrorFormatPhone = true;
                } else {
                    this.formSetting.IsErrorFormatPhone = false;
                }
            }

            if (this.formSetting.CityId == null || this.formSetting.CityId <= 0) {
                isValid = false;
            }

            if (this.formSetting.CarCondition == null || this.formSetting.CarCondition <= 0) {
                isValid = false;
            }

            // validate Fullname
            if (this.formSetting)
                $(".box-account .item-model").each(function () {

                    if ($(this).find(".sub-model-item .box-model-item").length > 0) {
                        $(this).find(".sub-model-item .box-model-item").each(function () {
                            let fromyear = parseInt($(this).find("select.from-year").val());
                            let toyear = parseInt($(this).find("select.to-year").val());

                            $(this).find(".error").remove();
                            //2. check if fromdate bigger than todate empty
                            if (fromyear > 0 && toyear > 0 && fromyear > toyear) {
                                $(this).append('<div class="error pull-left text-right full-width pd-top-5"><div>To date must be greater than From date.</div></div>');
                                isValid = false;
                            }
                        });
                    }

                });

            if (!isValid) return;

            //2. Insert DB
            let lstBrandSelling: BrandModelSelling[] = [];
            $(".box-account .item-model").each(function () {

                let brandId = parseInt($(this).attr("data-brandid"));

                if ($(this).find(".sub-model-item .box-model-item").length > 0) {
                    $(this).find(".sub-model-item .box-model-item").each(function () {
                        let modelId = parseInt($(this).attr("data-modelid"));
                        let fromyear = parseInt($(this).find("select.from-year").val());
                        let toyear = parseInt($(this).find("select.to-year").val());
                        lstBrandSelling.push(new BrandModelSelling(brandId, modelId, fromyear, toyear));
                    });
                } else {
                    lstBrandSelling.push(new BrandModelSelling(brandId, 0, 0, 0));
                }

            });

            let profile = new DealerAccountInfo();
            profile.DisplayName = $.trim(this.formSetting.DisplayName);
            profile.Email = $.trim(this.formSetting.Email);
            profile.Phone = $.trim(this.formSetting.Phone);
            profile.CityId = this.formSetting.CityId;
            profile.CarCondition = this.formSetting.CarCondition;

            let username = dealerInfo.UserName;
            let key = dealerInfo.ActivateCode;

            this.dealerService.updateDealerAccount(username, key, profile, lstBrandSelling).subscribe(p => {
                if (p != null || p != undefined) {
                    if (p.Success) {

                        //update account name
                        let loginInfo = this.dealerService.getLoginInfo();
                        if (loginInfo != null && loginInfo.UserName != null) {
                            if (loginInfo.DisplayName != this.formSetting.DisplayName || loginInfo.Phone != this.formSetting.Phone) {
                                this.constLeads.LoginDisplayName = this.formSetting.DisplayName;
                                this.constLeads.LoginPhone = this.formSetting.Phone;
                                loginInfo.DisplayName = this.formSetting.DisplayName;
                                loginInfo.Phone = this.formSetting.Phone;

                                localStorage.removeItem(this.dealerService.loginKey);
                                loginInfo.TimeLogin = new Date().getTime();
                                localStorage.setItem(this.dealerService.loginKey, JSON.stringify(loginInfo));
                            }
                        }

                        this.dialog.open(DialogInfoComponent, {
                            width: '350px',
                            data: { message: "Save change successful!!" }
                        });

                    } else {

                        if (p.ErrorCode != null && p.ErrorCode == ErrorCodeEnum.AccountLocked) {
                            this.constLeads.resetLogin();
                            this.router.navigate(['/'], { queryParams: { returnUrl: this.constLeads.AccountSettingLink } });
                            return;
                        }

                        this.dialog.open(DialogInfoComponent, {
                            width: '350px',
                            data: { message: p.Message }
                        });
                    }
                }
                this.constLeads.ProgressValue = 100;
            });
        } else {
            this.constLeads.resetLogin();
            this.router.navigate(['/'], { queryParams: { returnUrl: this.constLeads.AccountSettingLink } });
        }
    }
    public checkConfirmPass() {
        return this.formChangePass.NewPass == this.formChangePass.Confirm;
    }
    // summit change pass
    public submitPass() {

        let dealerInfo = this.dealerService.getLoginInfo();
        if (dealerInfo != null) {

            this.formChangePass.Submitted = true;
            let hasError = false;
            if (this.formChangePass.OldPass == null || this.formChangePass.OldPass == '') {
                hasError = true;
            }
            if (this.formChangePass.NewPass == null || this.formChangePass.NewPass == '') {
                hasError = true;
            } else {
                if (this.formChangePass.NewPass.length < 6) {
                    hasError = true;
                }
            }

            if (this.formChangePass.Confirm == null || this.formChangePass.OldPass == '') {
                hasError = true;
            }

            if (hasError) return;

            let newPass = this.formChangePass.NewPass;
            let confirmPass = this.formChangePass.Confirm;
            if (newPass != confirmPass) {
                this.formChangePass.IsError = true;
                return;
            }

            this.formChangePass.IsError = false;

            this.constLeads.ProgressValue = 90;

            this.textError = '';
            let username = dealerInfo.UserName;
            let key = dealerInfo.ActivateCode;
            this.dealerService.updateChangePassOnly(username, key, this.formChangePass.OldPass, newPass).pipe(first()).subscribe(p => {

                setTimeout(() => {
                    if (p != null) {
                        if (p.Success) {

                            this.closeChangePass();

                            this.dialog.open(DialogInfoComponent, {
                                width: '350px',
                                data: { message: "Change password successful!!" }
                            });

                        } else {

                            if (p.ErrorCode != null && p.ErrorCode == ErrorCodeEnum.AccountLocked) {
                                this.constLeads.resetLogin();
                                this.router.navigate(['/'], { queryParams: { returnUrl: this.constLeads.AccountSettingLink } });
                            } else {
                                this.textError = p.Message;
                            }
                        }
                    }
                    this.constLeads.ProgressValue = 100;

                }, 200);

            });
        }
        else {
            this.constLeads.resetLogin();
            this.router.navigate(['/'], { queryParams: { returnUrl: this.constLeads.AccountSettingLink } });
        }

    }

    // Get data for combobox city
    private getCityCombobox() {
        let dealerInfo = this.dealerService.getLoginInfo();
        if (dealerInfo != null) {
            let username = dealerInfo.UserName;
            let key = dealerInfo.ActivateCode;
            this.cityService.getAllCityV2(username, key).subscribe(p => {
                this.cityModel = p;
            });
        }
    }

    // Get data for combobox brand
    private getBrandCombobox() {
        let dealerInfo = this.dealerService.getLoginInfo();
        if (dealerInfo != null) {
            let username = dealerInfo.UserName;
            let key = dealerInfo.ActivateCode;
            this.brandService.getBrandInfoOfAccountPrimary(username, key).subscribe(p => {
                this.brandModel = p;
                this.brandModelPool = JSON.stringify(p);
            });
        }
    }
}
