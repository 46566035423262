﻿import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Title } from '@angular/platform-browser';
import { first } from 'rxjs/operators';
import * as $ from 'jquery';
import { Router, ActivatedRoute } from '@angular/router';
import { ConstLeads } from '../../utils/const.leads';
import { DealerService } from '../../bsl/dealer.service';
import { BrandService } from '../../bsl/brand.service';
import { SearchFormPendingModel } from '../../bsl/model/search-model';
import { UtilsString } from '../../utils/utils-string';
import { ExcelService } from '../../bsl/excel.service';
import { LeadsModel } from '../../bsl/model/leads-model';
import { BrandModel } from '../../bsl/model/brand-model';
import { DialogInfoComponent } from '../../views/partial/dialog/info/dialog-info.component';
import { DialogConfirmComponent } from '../../views/partial/dialog/confirm/dialog-confirm.component';
import { DealerLoginInfo } from '../../bsl/model/dealer-login-info';
import { ErrorCodeEnum, ExportType, LeadType } from '../../utils/enums/leads-type.enum';
import { SellerInfo } from '../../bsl/model/seller-info';
import { Customer } from '../../bsl/model/customer-model';
import { EmployeeTypeEnum, PartnerTypeEnum } from '../../utils/enums/dealeraccount.enum';

@Component({
    selector: 'app-contacted',
    templateUrl: './contacted.component.html'
})
export class ContactedComponent implements OnInit {

    public assigneeModel: DealerLoginInfo[];
    public brandModel: BrandModel[];
    public formSearch: SearchFormPendingModel;
    public currentPageIndex: number = 1;
    public totalRowPaging = 0;
    public itemPerPage: number;
    public listDataContacted: LeadsModel[];
    public createNote = { isShowNote: false, contentNote: "", leadsId: 0, submitted: false };
    public isPrimaryAccount = false;
    public hasLoaded = false;
    public sellerInfo: SellerInfo;
    public rejectReasonForm = { isShow: false, reason: "", reasonOther: '', submitted: false, leadDealerId: 0, status: 0, keyLeads: '' };
    public dealerInfo = this.dealerService.getLoginInfo();
    public listStatus = this.constLeads.LeadStatusListConst;
    public listStatusFilter: any[];
    public listSourceFilter: any[];
    public openLeadInfoForm = false;
    public customerInfor: Customer;
    public partnerTypeEnum = PartnerTypeEnum;
    public employeeTypeEnum = EmployeeTypeEnum;
    constructor(
        private constLeads: ConstLeads,
        private dealerService: DealerService,
        private titleService: Title,
        private utilsString: UtilsString,
        private excelService: ExcelService,
        public dialog: MatDialog,
        private router: Router,
        private activeRouter: ActivatedRoute,
        private brandService: BrandService) {
        this.formSearch = new SearchFormPendingModel();
        this.formSearch.BrandId = 0;
        this.formSearch.AssigneeId = 0;
        this.formSearch.FromDateStr = "";
        this.formSearch.ToDateStr = "";
        this.formSearch.Keyword = "";
        this.itemPerPage = this.constLeads.PageItem;

        this.rejectReasonForm.isShow = false;
        this.rejectReasonForm.reason = "";
        this.rejectReasonForm.reasonOther = "";
        this.rejectReasonForm.submitted = false;
        this.rejectReasonForm.leadDealerId = 0;
        this.rejectReasonForm.status = 0;
        this.rejectReasonForm.keyLeads = "";
        this.sellerInfo = new SellerInfo();
    }

    public async ngOnInit() {
        // Setting common page
        this.constLeads.innitContacted();
        this.customerInfor = new Customer();
        this.formSearch.PageId = LeadType.HotLead;

        // get login info
        if (this.dealerInfo != null) {
            this.constLeads.LoginDisplayName = this.dealerInfo.DisplayName;
            this.constLeads.LoginPhone = this.dealerInfo.Phone;

            // set title page
            this.titleService.setTitle(this.constLeads.TitleContactedPage);

            // get data for combobox
            this.getBrandCombobox();
            this.initSearchData();
            // get data for table pending
            this.getData(1);

            var isPrimary = await this.dealerService.isPrimaryAccount();
            if (isPrimary) {
                this.getAssingeeCombobox();
                this.isPrimaryAccount = true;
            }
        } else {
            this.constLeads.resetLogin();
            this.router.navigate(['/'], { queryParams: { returnUrl: this.constLeads.ContactedLink } });
        }
    }

    private initSearchData() {
        this.dealerService.initHotLeadSearchData().subscribe(p => {
            if (p.Data != null) {
                this.listStatusFilter = p.Data.ListStatus;
                this.listSourceFilter = p.Data.ListSource;
            }
        });
    }

    public searchChange() {
        this.hasLoaded = false;
        this.getData(1);
    }

    public getPaggingText() {
        let s = `Showing <b>${this.itemPerPage * (this.currentPageIndex - 1) + 1}</b>
                    to <b>${this.itemPerPage * this.currentPageIndex}</b>
                of <b class="clr-red">${this.totalRowPaging}</b> results`;

        return s;
    }
    // Get data for combobox brand
    private getBrandCombobox() {
        if (this.dealerInfo != null) {
            let username = this.dealerInfo.UserName;
            let key = this.dealerInfo.ActivateCode;
            this.brandService.getAll(username, key).subscribe(p => {
                this.brandModel = p;
            });
        }
    }

    // get data 
    public getData(pageIndex: number) {
        this.currentPageIndex = pageIndex;
        this.formSearch.PageIndex = pageIndex;
        if (this.dealerInfo != null) {
            setTimeout(() => {
                this.constLeads.ProgressValue = 99;
                this.formSearch.UserName = this.dealerInfo.UserName;
                this.formSearch.Key = this.dealerInfo.ActivateCode;

                if (this.formSearch != null && this.formSearch != undefined) {
                    if (this.formSearch.FromDate != null) {
                        this.formSearch.FromDateStr = this.utilsString.ConvertDate2str(new Date(this.formSearch.FromDate));
                    }

                    if (this.formSearch.ToDate != null) {
                        this.formSearch.ToDateStr = this.utilsString.ConvertDate2str(new Date(this.formSearch.ToDate));
                    }
                }

                this.dealerService.getListLeads(this.formSearch).subscribe(p => {
                    if (p != null || p != undefined) {
                        if (p.Success) {
                            this.listDataContacted = <LeadsModel[]>p.Data;
                            this.totalRowPaging = p.TotalRow;

                            // trường hợp currentPageIndex > 1 và đang đổi status và leads đó là item cuối cùng của trang đó thì cần trừ currentPageIndex -1 và load lại dữ liệu
                            if (this.currentPageIndex > 1 && p.TotalRow == (this.itemPerPage * (this.currentPageIndex - 1)) && this.listDataContacted.length == 0) {
                                this.currentPageIndex = this.currentPageIndex - 1;
                                this.getData(this.currentPageIndex);
                            }

                        } else {

                            if (p.ErrorCode != null && p.ErrorCode == ErrorCodeEnum.AccountLocked) {
                                this.constLeads.resetLogin();
                                this.router.navigate(['/'], { queryParams: { returnUrl: this.constLeads.ContactedLink } });
                                return;
                            }

                            this.listDataContacted = [];
                        }
                    } else {
                        this.listDataContacted = [];
                    }
                    this.constLeads.ProgressValue = 100;
                    this.hasLoaded = true;
                });
            }, 100);
        }
    }

    //export excel
    public exportExcel() {
        if (this.dealerInfo != null) {
            this.constLeads.ProgressValue = 70;
            setTimeout(() => {
                this.constLeads.ProgressValue = 90;
                this.formSearch.UserName = this.dealerInfo.UserName;
                this.formSearch.Key = this.dealerInfo.ActivateCode;

                if (this.formSearch != null && this.formSearch != undefined) {
                    if (this.formSearch.FromDate != null) {
                        this.formSearch.FromDateStr = this.utilsString.ConvertDate2str(new Date(this.formSearch.FromDate));
                    }

                    if (this.formSearch.ToDate != null) {
                        this.formSearch.ToDateStr = this.utilsString.ConvertDate2str(new Date(this.formSearch.ToDate));
                    }
                }

                this.dealerService.doExportLead(this.formSearch).subscribe(p => {
                    if (p != null || p != undefined) {
                        if (p.Success) {
                            const data = <LeadsModel[]>p.Data;
                            if (data.length > 0) {
                                if (this.dealerInfo.PartnerType == this.partnerTypeEnum.Financer) {
                                    this.excelService.exportLeadWithDetailCustomer(data, this.constLeads.TitleContactedPage);
                                } else
                                    this.excelService.exportExcel(data, this.constLeads.TitleContactedPage);
                            } else {
                                this.dialog.open(DialogInfoComponent, {
                                    width: '350px',
                                    data: { message: this.constLeads.EmptyDataFile }
                                });
                            }

                        } else {

                            if (p.ErrorCode != null && p.ErrorCode == ErrorCodeEnum.AccountLocked) {
                                this.constLeads.resetLogin();
                                this.router.navigate(['/'], { queryParams: { returnUrl: this.constLeads.ContactedLink } });
                                return;
                            }

                            this.dialog.open(DialogInfoComponent, {
                                width: '350px',
                                data: { message: this.constLeads.ErrorDataFile }
                            });
                        }
                    }
                    this.constLeads.ProgressValue = 100;
                });

            }, 100);
        }

    }

    //open note
    public openNote(leadsId, noteContent) {
        this.createNote.isShowNote = true;
        this.createNote.contentNote = noteContent;
        this.createNote.leadsId = leadsId;
    }

    // Create feedback for dealer
    public submitNote() {
        this.createNote.submitted = true;
        if (this.createNote.contentNote == null || this.createNote.contentNote == "") { return; }
        if (this.dealerInfo != null) {

            let username = this.dealerInfo.UserName;
            let key = this.dealerInfo.ActivateCode;
            let noteContent = this.createNote.contentNote;
            let leadDealerId = this.createNote.leadsId;
            this.dealerService.createNote(username, key, leadDealerId, noteContent).pipe(first()).subscribe(p => {

                this.constLeads.ProgressValue = 90;
                setTimeout(() => {
                    if (p != null) {

                        if (p.Success) {
                            this.getData(this.currentPageIndex);

                            this.closeNote();
                            this.createNote.submitted = false;
                        } else {

                            if (p.ErrorCode != null && p.ErrorCode == ErrorCodeEnum.AccountLocked) {
                                this.constLeads.resetLogin();
                                this.router.navigate(['/'], { queryParams: { returnUrl: this.constLeads.ContactedLink } });
                                return;
                            }

                            this.dialog.open(DialogInfoComponent, {
                                width: '350px',
                                data: { message: p.Message }
                            });
                        }
                    }

                    this.constLeads.ProgressValue = 100;
                }, 200);
            });
        }
    }

    //close note
    public closeNote() {
        this.createNote.submitted = false;
        this.createNote.isShowNote = false;
        this.createNote.contentNote = "";
        this.createNote.leadsId = 0;
    }

    //Change status
    public changeStatus(id, value, leadKey) {
        if (this.dealerInfo != null) {
            if (parseInt(id) > 0 && parseInt(value) > 0) {
                if (parseInt(value) != 4) {
                    const dialogRef = this.dialog.open(DialogConfirmComponent, {
                        width: '350px',
                        data: {
                            message: this.constLeads.ConfirmContentStatus,
                            btnOkText: 'Ok',
                            btnCancelText: 'Cancel'
                        }
                    });

                    dialogRef.afterClosed().subscribe(result => {
                        if (result) {
                            this.dealerService.updateStatusContacted(this.dealerInfo.UserName, this.dealerInfo.ActivateCode, leadKey, id, value, '')
                                .pipe(first())
                                .subscribe(p => {
                                    if (p != null) {
                                        if (p.Success) {
                                            this.getData(this.currentPageIndex);
                                        } else {

                                            if (p.ErrorCode != null && p.ErrorCode == ErrorCodeEnum.AccountLocked) {
                                                this.constLeads.resetLogin();
                                                this.router.navigate(['/'], { queryParams: { returnUrl: this.constLeads.ContactedLink } });
                                                return;
                                            }

                                            this.dialog.open(DialogInfoComponent, {
                                                width: '350px',
                                                data: { message: p.Message }
                                            });
                                        }
                                    }
                                });
                        } else {
                            this.getData(this.currentPageIndex);
                        }
                    });

                } else {

                    if (parseInt(value) == 4) {
                        this.openRejectForm(id, leadKey, 4); // open reason form
                    }
                }
            }
        } else {
            let returnUrl = this.activeRouter.snapshot.queryParams['returnUrl'] || '/dashboard';
            this.router.navigate(['/'], { queryParams: { returnUrl: returnUrl } });
        }
    }

    // Open reject reason
    public openRejectForm(id, keyLeads, status) {
        this.rejectReasonForm.isShow = true;
        this.rejectReasonForm.leadDealerId = id;
        this.rejectReasonForm.status = status;
        this.rejectReasonForm.keyLeads = keyLeads;
    }

    // Open reject reason
    public closeRejectForm() {
        this.rejectReasonForm.isShow = false;
        this.rejectReasonForm.leadDealerId = 0;
        this.rejectReasonForm.status = 0;
        this.rejectReasonForm.keyLeads = "";

        this.getData(this.currentPageIndex);
    }

    //On change for reason combobox
    public submitRejectReason() {
        if (this.dealerInfo != null) {
            this.rejectReasonForm.submitted = true;

            if (this.rejectReasonForm.reasonOther != null && $.trim(this.rejectReasonForm.reasonOther) == "") {
                this.rejectReasonForm.reasonOther = "";
            }

            if (this.rejectReasonForm.reason == null || this.rejectReasonForm.reason == '') return;
            if (this.rejectReasonForm.reason == 'Other' && (this.rejectReasonForm.reasonOther == null || this.rejectReasonForm.reasonOther == '')) return;

            let strNote = "";
            strNote = this.rejectReasonForm.reason == 'Other' ? $.trim(this.rejectReasonForm.reasonOther) : this.rejectReasonForm.reason;
            this.dealerService.updateStatusContacted(this.dealerInfo.UserName, this.dealerInfo.ActivateCode, this.rejectReasonForm.keyLeads, this.rejectReasonForm.leadDealerId, this.rejectReasonForm.status, strNote)
                .pipe(first())
                .subscribe(p => {
                    if (p != null) {
                        if (p.Success) {
                            this.getData(this.currentPageIndex);
                            this.closeRejectForm();
                        } else {

                            if (p.ErrorCode != null && p.ErrorCode == ErrorCodeEnum.AccountLocked) {
                                this.constLeads.resetLogin();
                                this.router.navigate(['/'], { queryParams: { returnUrl: this.constLeads.ContactedLink } });
                                return;
                            }

                            this.dialog.open(DialogInfoComponent, {
                                width: '350px',
                                data: { message: p.Message }
                            });
                        }
                    }
                });
        } else {
            let returnUrl = this.activeRouter.snapshot.queryParams['returnUrl'] || '/dashboard';
            this.router.navigate(['/'], { queryParams: { returnUrl: returnUrl } });
        }
    }

    // read more 
    public readMore(leadId) {
        if (leadId > 0) {

            $(".item-view-main-" + leadId).find("div.none").each(function () {
                $(this).removeClass("none");
            });

            $(".item-view-main-" + leadId).find(".btn-read-more").hide();
        }
    }

    // Get data for combobox  sub account
    private getAssingeeCombobox() {
        if (this.dealerInfo != null) {
            let username = this.dealerInfo.UserName;
            let key = this.dealerInfo.ActivateCode;
            this.dealerService.getAllDealerAccount(username, key, this.dealerInfo.DealerId).subscribe(p => {
                if (p != null || p != undefined) {
                    if (p.Success) {
                        this.assigneeModel = <DealerLoginInfo[]>p.Data;
                    } else {
                        this.assigneeModel = [];
                    }
                }
            });
        }
    }

    public openSeller(productId) {
        this.sellerInfo.ProductId = productId;

        if (this.dealerInfo != null) {
            let key = this.dealerInfo.ActivateCode;
            let username = this.dealerInfo.UserName;
            this.dealerService.getSellerById(productId, username, key).subscribe(p => {
                if (p != null || p != undefined) {
                    if (p.Success) {
                        this.sellerInfo = <SellerInfo>p.Data;
                        this.sellerInfo.IsShowSeller = true;
                    } else {
                        this.dialog.open(DialogInfoComponent, {
                            width: '350px',
                            data: { message: p.Message }
                        });
                    }
                }
            });
        }
    }

    public closeSeller() {
        this.sellerInfo = new SellerInfo();
    }

    public checkIsNullOrEmpty(obj: any, noZero = false) {
        if (obj === undefined || obj === null || obj === '' || obj.trim(' ') === '') {
            return true;
        }

        if (noZero && obj.toString() === '0') {
            return true;
        }

        return false;
    }

    formatNumber(obj: number) {
        if (obj === undefined || obj === null || obj.toString() === '0' || obj.toString() === 'NaN') {
            return '';
        }

        return obj.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
    showLeadDetail(lead: LeadsModel) {
        if (lead == null || this.checkIsNullOrEmpty(lead.LeadsPhone)) {
            this.dialog.open(DialogInfoComponent, {
                width: '350px',
                data: { message: "This Lead/User is not exist!" }
            });
            return;
        }
        //get infor
        this.dealerService.getCustomerDetailByPhoneAndEmail(lead.LeadsPhone, lead.LeadsEmail, this.dealerInfo.UserName, this.dealerInfo.ActivateCode).subscribe(res => {
            if (res != null) {
                if (!res.Success) {
                    this.dialog.open(DialogInfoComponent, {
                        width: '350px',
                        data: { message: res.Message }
                    });
                } else if (res.Data != null) {
                    this.customerInfor = res.Data as Customer;
                    this.openLeadInfoForm = true;
                }
            }
        });
    }
}
