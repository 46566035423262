﻿import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ConstLeads } from '../../utils/const.leads';
import { DealerService } from '../../bsl/dealer.service';
import { CityModel } from '../../bsl/model/city-model';
import { CityService } from '../../bsl/city.service';
import { DealerGroupType, DealerSummary } from '../../bsl/model/dashboard-model';
import { UtilsString } from '../../utils/utils-string';
import { ExcelService } from '../../bsl/excel.service';
import { DialogInfoComponent } from '../../views/partial/dialog/info/dialog-info.component';
import { SearchFormModel } from '../../bsl/model/search-model';
import { DealerLoginInfo } from '../../bsl/model/dealer-login-info';
import { ErrorCodeEnum } from '../../utils/enums/leads-type.enum';
import { race } from 'rxjs';
import { st } from '@angular/core/src/render3';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {

    public cityModel: CityModel[];
    public assigneeModel: DealerLoginInfo[];
    public listDataDashboard: DealerSummary[];
    public formSearch: SearchFormModel;
    public currentPageIndex: number = 1;
    public totalRowPaging = 0;
    public itemPerPage: number;
    public isPrimaryAccount = false;
    public hasLoaded = false;
    public listStatus = this.constLeads.LeadStatusListConst;
    public groupType: DealerGroupType[];
    constructor(
        private constLeads: ConstLeads,
        private dealerService: DealerService,
        private titleService: Title,
        private cityService: CityService,
        private utilsString: UtilsString,
        private excelService: ExcelService,
        private router: Router,
        public dialog: MatDialog) {

        this.formSearch = new SearchFormModel();
        this.formSearch.FromDate = "";
        this.formSearch.ToDate = "";
        this.formSearch.CityId = 0;
        this.formSearch.AssigneeId = 0;
        this.totalRowPaging = 0;
        this.itemPerPage = this.constLeads.PageItem;
    }

    // Document init
    public async ngOnInit() {

        // get login info
        let dealerInfo = this.dealerService.getLoginInfo();
        if (dealerInfo != null) {
            this.constLeads.LoginDisplayName = dealerInfo.DisplayName;
            this.constLeads.LoginPhone = dealerInfo.Phone;

            // Setting common page
            this.constLeads.initDashboard();

            // set title page
            this.titleService.setTitle(this.constLeads.TitlePageDashboard);

            var isPrimary = await this.dealerService.isPrimaryAccount();
            if (isPrimary) {
                this.getAssingeeCombobox();
                this.isPrimaryAccount = true;
            }

            // get city combobox
            this.getCityCombobox();

            // get data for table dashboard
            this.getData(1);
        } else {
            this.constLeads.resetLogin();
            this.router.navigate(['/'], { queryParams: { returnUrl: this.constLeads.DashboardLink } });
        }
    }

    // Property for form search
    public get form() { return this.formSearch; }

    // Search event handler
    public searchChange() {
        this.hasLoaded = false;
        this.getData(1);
    }

    // Get data for combobox city
    private getCityCombobox() {
        let dealerInfo = this.dealerService.getLoginInfo();
        if (dealerInfo != null) {
            let username = dealerInfo.UserName;
            let key = dealerInfo.ActivateCode;
            this.cityService.getAllCity(username, key).subscribe(p => {
                this.cityModel = p;
            });
        }
    }

    // Get data for combobox sub account
    private getAssingeeCombobox() {
        let dealerInfo = this.dealerService.getLoginInfo();
        if (dealerInfo != null) {
            let username = dealerInfo.UserName;
            let key = dealerInfo.ActivateCode;
            this.dealerService.getAllDealerAccount(username, key, dealerInfo.DealerId).subscribe(p => {
                if (p != null || p != undefined) {
                    if (p.Success) {
                        this.assigneeModel = <DealerLoginInfo[]>p.Data;
                    } else {
                        this.assigneeModel = [];
                    }
                }
            });
        }
    }

    // Exec search
    public getData(pageIndex: number) {
        this.currentPageIndex = pageIndex;
        let dealerInfo = this.dealerService.getLoginInfo();
        if (dealerInfo != null) {

            setTimeout(() => {
                this.constLeads.ProgressValue = 99;
                let username = dealerInfo.UserName;
                let key = dealerInfo.ActivateCode;
                let fromDate = "";
                let toDate = "";
                let cityId = 0;
                let assigneeId = 0;

                if (this.formSearch != null && this.formSearch != undefined) {
                    if (this.formSearch.FromDate != null && this.formSearch.FromDate != "") {
                        fromDate = this.utilsString.ConvertDate2str(new Date(this.formSearch.FromDate));
                    }

                    if (this.formSearch.ToDate != null && this.formSearch.ToDate != "") {
                        toDate = this.utilsString.ConvertDate2str(new Date(this.formSearch.ToDate));
                    }

                    if (this.formSearch.CityId > 0) {
                        cityId = this.formSearch.CityId;
                    }

                    if (this.formSearch.AssigneeId > 0) {
                        assigneeId = this.formSearch.AssigneeId;
                    }
                }

                this.dealerService.getListDashboard(username, key, fromDate, toDate, cityId, assigneeId, pageIndex).subscribe(p => {
                    if (p != null || p != undefined) {
                        if (p.Success) {
                            this.listDataDashboard = <DealerSummary[]>p.Data.ReportValue;
                            this.groupType = <DealerGroupType[]>p.Data.ReportGroup
                            this.totalRowPaging = p.TotalRow;
                        } else {

                            if (p.ErrorCode != null && p.ErrorCode == ErrorCodeEnum.AccountLocked) {
                                this.constLeads.resetLogin();
                                this.router.navigate(['/'], { queryParams: { returnUrl: this.constLeads.DashboardLink } });
                                return;
                            }

                            this.listDataDashboard = [];
                        }
                    } else {
                        this.listDataDashboard = [];
                    }
                    this.constLeads.ProgressValue = 100;
                    this.hasLoaded = true;
                });
            }, 100);
        }
    }

    public getSummaryByTypeAndStatus(type: number, status: number) {
        var rs = this.listDataDashboard.find(f => f.Type === type && f.Status === status);
        if (rs) return rs.TotalLead;
        return 0;
    }

    public sumTotalOnColumn(status: number) {
        var rs = 0;
        if (status == -1)
            this.groupType.forEach(t => {
                rs += t.Total;
            });
        else
            this.listDataDashboard.forEach(item => {
                if (item.Status == status) {
                    rs += item.TotalLead;
                }
            });
        return rs;
    }
    //export excel
    public exportExcel() {
        let dealerInfo = this.dealerService.getLoginInfo();
        if (dealerInfo != null) {

            setTimeout(() => {
                this.constLeads.ProgressValue = 99;
                let username = dealerInfo.UserName;
                let key = dealerInfo.ActivateCode;
                let fromDate = "";
                let toDate = "";
                let cityId = 0;
                let assigneeId = 0;

                if (this.formSearch != null && this.formSearch != undefined) {
                    if (this.formSearch.FromDate != null && this.formSearch.FromDate != "") {
                        fromDate = this.utilsString.ConvertDate2str(new Date(this.formSearch.FromDate));
                    }

                    if (this.formSearch.ToDate != null && this.formSearch.ToDate != "") {
                        toDate = this.utilsString.ConvertDate2str(new Date(this.formSearch.ToDate));
                    }

                    if (this.formSearch.CityId > 0) {
                        cityId = this.formSearch.CityId;
                    }
                    if (this.formSearch.AssigneeId > 0) {
                        assigneeId = this.formSearch.AssigneeId;
                    }
                }

                this.dealerService.getListDashboardExport(username, key, fromDate, toDate, cityId, assigneeId).subscribe(p => {
                    if (p != null || p != undefined) {
                        if (p.Success) {
                            const data = <DealerSummary[]>p.Data;
                            if (data.length > 0) {
                                this.excelService.generateDashboard(data);
                            } else {
                                this.dialog.open(DialogInfoComponent, {
                                    width: '350px',
                                    data: { message: this.constLeads.EmptyDataFile }
                                });
                            }

                        } else {

                            if (p.ErrorCode != null && p.ErrorCode == ErrorCodeEnum.AccountLocked) {
                                this.constLeads.resetLogin();
                                this.router.navigate(['/'], { queryParams: { returnUrl: this.constLeads.DashboardLink } });
                                return;
                            }

                            this.dialog.open(DialogInfoComponent, {
                                width: '350px',
                                data: { message: this.constLeads.ErrorDataFile }
                            });
                        }
                    }
                    this.constLeads.ProgressValue = 100;
                });

            }, 100);
        }
    }
}

