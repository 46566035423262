﻿import * as $ from 'jquery';
import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';

import { ConstLeads } from './utils/const.leads';
import { DealerService } from './bsl/dealer.service';
import { DialogInfoComponent } from './views/partial/dialog/info/dialog-info.component';
import { DialogConfirmComponent } from './views/partial/dialog/confirm/dialog-confirm.component';
import { ErrorCodeEnum } from './utils/enums/leads-type.enum';

@Component({
    selector: 'app-leads',
    templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {

    public formFeedback: FormGroup;
    public submittedFeed = false;
    public formFeedbackSpaceOnly = false;

    constructor(
        private constLeads: ConstLeads,
        private dealerService: DealerService,
        private formBuilder: FormBuilder,
        private router: Router,
        public dialog: MatDialog) { }

    public ngOnInit(): void {

        this.formFeedback = this.formBuilder.group({
            contentFeedback: ['', Validators.required]
        });

    }

    // Public access to const & service
    public get process() { return this.constLeads; }
    public get dealer() { return this.dealerService; }
    public get feedback() { return this.formFeedback.controls; }


    public logout() {

        const dialogRef = this.dialog.open(DialogConfirmComponent, {
            width: '350px',
            data: {
                message: this.constLeads.ConfirmSignOut,
                btnOkText: 'Ok',
                btnCancelText: 'Cancel'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.dealer.logout(); 
            } 
        });
    }
    // Create feedback for dealer
    public submitFeedback() {
        this.submittedFeed = true;

        if ($.trim(this.formFeedback.value.contentFeedback) == "") {
            this.formFeedbackSpaceOnly = true;
            this.formFeedback.get('contentFeedback').reset();
            return;
        }

        if (this.formFeedback.invalid) { return; }
        let dealerInfo = this.dealerService.getLoginInfo();
        if (dealerInfo != null) {

            this.formFeedbackSpaceOnly = false;
            let username = dealerInfo.UserName;
            let key = dealerInfo.ActivateCode;
            let feedbackContent = $.trim(this.formFeedback.value.contentFeedback);
            this.dealerService.createFeedback(username, key, feedbackContent).pipe(first()).subscribe(p => {

                this.constLeads.ProgressValue = 90;
                setTimeout(() => {
                    if (p != null) {

                        if (p.Success) {
                            this.dialog.open(DialogInfoComponent, {
                                width: '350px',
                                data: { message: this.constLeads.CreateFeedbackSucess }
                            });
                        } else {

                            if (p.ErrorCode != null && p.ErrorCode == ErrorCodeEnum.AccountLocked) {
                                this.constLeads.resetLogin();
                                this.router.navigate(['/'], { queryParams: { returnUrl: this.constLeads.DashboardLink } });
                                return;
                            }

                            this.dialog.open(DialogInfoComponent, {
                                width: '350px',
                                data: { message: p.Message }
                            });
                        }

                        this.formFeedback.get('contentFeedback').reset();
                        this.constLeads.hidePopupFeedback();
                        this.submittedFeed = false;
                    }

                    this.constLeads.ProgressValue = 100;
                }, 200);
            });
        }
    }

    public cancelFeedback(): void {
        this.formFeedback.get('contentFeedback').reset();
        this.submittedFeed = false;
        this.constLeads.hidePopupFeedback();
    }
}