export enum ErrorCodeEnum {
    AccountLocked = 1,
    AccountNotExist = 2
}

export enum ExportType {
    PendingLeads = 1,
    PendingOnboardingLeads = 2,
    OnboardingLeads = 3
}

export enum LeadType {
    PendingLeads = 1,
    PendingOnboardingLeads = 2,
    HotLead = 3,
    ArchivedLeads = 4,
}