﻿import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConstLeads } from '../../utils/const.leads';
import { DealerService } from '../../bsl/dealer.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import * as $ from 'jquery';
import { AuthGuard } from '../../utils/authorize/auth.guard';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html'
})

export class LoginComponent implements OnInit {

    public loginForm: FormGroup;
    public returnUrl: string;
    public submitted = false;
    public msgError = "";
    AuthGuard: AuthGuard;
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private dealerService: DealerService,
        private constLeads: ConstLeads,
        private serviceTitle: Title) {
        this.constLeads.ProgressValue = 0;
    }

    public ngOnInit() {
        this.loginForm = this.formBuilder.group({
            userName: ['', Validators.required],
            password: ['', Validators.required]
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
        //this.dealerService.logoutNotRedirect();

        //this.constLeads.resetLogin();
        //check login
        if (this.dealerService.verifyAuthen())
            this.router.navigate(['/dashboard']);
        // set title page
        this.serviceTitle.setTitle(this.constLeads.TiltePageLogin);

        //reset menu collapse
        $(".sidebar-collapse").removeClass("sidebar-collapse");
    };

    // Create short call object for html call object
    public get f() { return this.loginForm.controls; }

    // Submit form
    public OnSubmit() {

        this.submitted = true;
        if (this.loginForm.invalid) { return; }

        this.constLeads.ProgressValue = 10;
        let user = this.loginForm.value.userName;
        let pass = this.loginForm.value.password;

        this.dealerService.getDealerByUsernamePass(user, pass).pipe(first()).subscribe(p => {

            this.constLeads.ProgressValue = 90;
            setTimeout(() => {

                if (p != null && p != undefined) {
                    if (p.Success) {
                        p.Data.TimeLogin = new Date().getTime();
                        localStorage.setItem(this.dealerService.loginKey, JSON.stringify(p.Data));
                        var rs = JSON.parse(localStorage.getItem(this.dealerService.loginKey));
                        console.log('login: ', rs);
                        this.msgError = "";
                        this.constLeads.ProgressValue = 100;
                        this.router.navigate([this.returnUrl]);
                    } else {
                        this.constLeads.ProgressValue = 100;
                        this.msgError = p.Message;
                    }
                }
            }, 200);
        });

    };
    forgotPassword() {
        // var y = window.outerHeight / 2 + window.screenY - 300;
        // var x = window.outerWidth / 2 + window.screenX - 500
        // window.open("https://philkotse.com/forgot-password", "", 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + 1000 + ', height=' + 600 + ', top=' + y + ', left=' + x);

        //window.open(this.configService.config.apiForgotPassword, "", "width = 950, height = 600");
        window.open("https://philkotse.com/forgot-password/lp", "_blank");
    }
}