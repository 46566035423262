﻿export class SellerInfo {
    public ProductId: number;
    public ContactName: string;
    public ContactAddress : string;
    public Phone : string;
    public Email: string;
    public UserType: number;
    public BrandAlias: string;
    public ModelAlias: string;
    public VersionAlias: string;
    public CityAlias: string;
    public Link: string;
    public Title: string;
    public IsShowSeller: boolean;
}