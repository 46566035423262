﻿import { LeadStatusModel } from '../bsl/model/lead-status-model';
import { LeadStatus } from '../utils/enums/lead-status.enum';
import { LeadType } from '../utils/enums/leads-type.enum';
export class ConstLeads {

    public PageItem = 10;
    public ProgressValue = 0;
    public IsLogin = true;
    public TitlePage: string;
    public ActiveMenu: number;
    public IsCollapseMenu = false;
    public IsShowFeedback = false;

    public LoginDisplayName: string;
    public LoginPhone: string;

    public TiltePageLogin = "Welcome to Philkotse's Lead platform";
    public TitlePageDashboard = "Dealership Lead dashboard";
    public TitlePendingPage = "Pending leads";
    public TitleContactedPage = "Hot leads";
    public TitleOnboardPage = "Archived leads";
    public TitleAccountPage = "Account settings";

    public CreateFeedbackSucess = "Your feedback has been successfully sent.";
    public EmptyDataFile = "No data for export file.";
    public ErrorDataFile = "Can't get data for export file.";

    public ConfirmTitleStatus = "philkotse.com";
    public ConfirmContentStatus = "Are you sure you want to change status?";
    public ConfirmSignOut = "Are you sure you want to sign out?";

    public TitlePageChangePass = "Change password";

    public ChangePass404 = "The link you are looking for is invalid. Please check the link address again.";

    //router
    public DashboardLink = "/dashboard";
    public AccountSettingLink = "/account-setting";
    public PendingLink = "/pending";
    public ContactedLink = "/contacted";
    public OnboardedLink = "/onboarded";
    public InquiryLink = "/inquiry";

    public LeadStatusListConst = [
        new LeadStatusModel(LeadStatus.Pending, "Pending", LeadType.PendingLeads),
        new LeadStatusModel(LeadStatus.Unanswered, "Unanswered", LeadType.PendingLeads),
        new LeadStatusModel(LeadStatus.Sold, "Sold", LeadType.ArchivedLeads),
        new LeadStatusModel(LeadStatus.Archived, "Archived", LeadType.ArchivedLeads),
        new LeadStatusModel(LeadStatus.Rejected, "Rejected", LeadType.ArchivedLeads),
        new LeadStatusModel(LeadStatus.HotLead, "Hot lead", LeadType.PendingOnboardingLeads),
    ]
    //];
    // Switch view to login page
    public resetLogin(): void {
        this.ProgressValue = 0;
        this.IsLogin = true;
        this.IsShowFeedback = false;
        this.TitlePage = this.TiltePageLogin;
    }

    // Switch view to other page except login page
    public resetOther(): void {
        setTimeout(() => {
            this.ProgressValue = 0;
            this.IsLogin = false;
        });
    }

    // Initial dashboard page
    public initDashboard(): void {
        setTimeout(() => {
            this.resetOther();
            this.TitlePage = this.TitlePageDashboard;
            this.ActiveMenu = 1;
        });
    }

    // Initial pending page
    public innitPending(): void {
        setTimeout(() => {
            this.resetOther();
            this.TitlePage = this.TitlePendingPage;
            this.ActiveMenu = 2;
        });
    }

    // Initial contacted page
    public innitContacted(): void {
        setTimeout(() => {
            this.resetOther();
            this.TitlePage = this.TitleContactedPage;
            this.ActiveMenu = 3;
        });
    }

    // Initial onboarded page
    public innitOnboarded(): void {
        setTimeout(() => {
            this.resetOther();
            this.TitlePage = this.TitleOnboardPage;
            this.ActiveMenu = 4;
        });
    }

    // Initial inquiry management page
    public innitInquiryManagement(): void {
        setTimeout(() => {
            this.resetOther();
            this.TitlePage = "Inquiry management";
            this.ActiveMenu = 5;
        });
    }

    // Initial account settings page
    public innitAccountSetting(): void {
        setTimeout(() => {
            this.resetOther();
            this.TitlePage = this.TitleAccountPage;
            this.ActiveMenu = 6;
        });
    }

    //Toggle size of menu page
    public toggleMenu(): void {
        setTimeout(() => {
            this.IsCollapseMenu = this.IsCollapseMenu ? false : true;
        });
    }

    // Show popup feedback 
    public showPopupFeedback(): void {
        this.IsShowFeedback = true;
    }

    // Hide popup feedback 
    public hidePopupFeedback(): void {
        this.IsShowFeedback = false;
    }
}