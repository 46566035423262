﻿import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from "rxjs";
import { map, catchError } from 'rxjs/operators';

import { ResponseData } from './model/response-data';
import { ModelEntity } from './model/models-model';
import { StringHelper } from '../bsl/stringHelper';

@Injectable({
    providedIn: 'root'
})

export class ModelService {

    constructor(private httpLient: HttpClient) { }

    public getAll(username: string, key: string, brandId: number): Observable<ResponseData> {
        var route = StringHelper.buildQueryStrings({ username: username, key: key, brandId: brandId });
        return this.httpLient.post<ResponseData>("../Dealer/GetItemBrandTemplateByBrand" + route, null)
            .pipe(
                map(
                    (item: any) => {
                        return item;
                    }),
                catchError(this.handleError)
            );
    }

    public getModelTemplate(modelId: number, modelName: string): Observable<ResponseData> {
        var route = StringHelper.buildQueryStrings({ modelId: modelId, modelName: modelName });
        return this.httpLient.post<ResponseData>("../Dealer/GetItemModelTemplateByBrand" + route, null)
            .pipe(
                map(
                    (item: any) => {
                        return item;
                    }),
                catchError(this.handleError)
            );
    }

    // Handler error for http
    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.log(error.error);
        }
        // return an observable with a user-facing error message
        return throwError(
            'Something bad happened; please try again later.');
    };
}